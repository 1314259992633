import moment from 'moment-timezone';

export const timeFormatArr = {
    0: 'h:mm A',
    1: 'HH:mm'
};

export const defaultProjects = {
    "0" : "Unassigned",
    "1" : "Writing",
    "2" : "Research",
    "3" : "Education/Training"
};

export const applicationServerPublicKey = 'BOFHWT0aeW4FcUUg4Mt0vjxplITnP1AjspiVoYvzAQu30rLtC-BjLi-BX65upbadm8o8RG9L9eQcNgHehav6bmg';

export const initialTaskFormState = { 
    name: '',
    description: '',
    projectID: '0',
    startDateTime: moment().toISOString(),
    duration: "3600",
    timeSpent: "0",
    status: 4,
    notificationStatus: 0,
    notificationEndStatus: 0,
    projectTag: '',
    tags: [],
    outlookInvite: true
}

export const statusStrings = {
    "0" : "Not Started",
    "1" : "Started",
    "2" : "Done",
    "3" : "Paused",
    "4" : "Waiting to Start"
};
  
export const difficultyStrings = {
    "1" : "Very Easy",
    "2" : "Easy",
    "3" : "Fair",
    "4" : "Hard",
    "5" : "Very Hard"
};
  
export const focusStrings = {
    "1" : "Very Low",
    "2" : "Low",
    "3" : "Average",
    "4" : "High",
    "5" : "Very High"
};

export const initialProjectFormState = {
    name: '',
    notes: '',
    color: '',
    tags: [] 
};

export const recurringTaskOptions = [
    { key: 0, value: 0, text: "None" },
    { key: 1, value: 1, text: "Daily" },
    { key: 2, value: 2, text: "Weekly" },
    { key: 3, value: 3, text: "Bi-Weekly" },
    { key: 4, value: 4, text: "Monthly" }
];

export const durationOptions = [
    { key: '300', value: '300', text: '5 Minutes'},
    { key: '600', value: '600', text: '10 Minutes'},
    { key: '900', value: '900', text: '15 Minutes'},
    { key: '1200', value: '1200', text: '20 Minutes'},
    { key: '1800', value: '1800', text: '30 Minutes' },
    { key: '2700', value: '2700', text: '45 Minutes'},
    { key: '3600', value: '3600', text: '1 Hour' },
    { key: '5400', value: '5400', text: '1 Hour 30 Minutes' },
    { key: '7200', value: '7200', text: '2 Hours' },
    { key: '9000', value: '9000', text: '2 Hours 30 Minutes' },
    { key: '10800', value: '10800', text: '3 Hours' },
    { key: '12600', value: '12600', text: '3 Hours 30 Minutes' },
    { key: '14400', value: '14400', text: '4 Hours' }
];

export const dfScale = [];
let i = 0;
for(let i = 1; i <= 5; i++) {
  dfScale.push({
    'key':i,
    'value':i,
    'text':difficultyStrings[i]
  });
}
export const fcScale = [];
for(i = 1; i <= 5; i++) {
  fcScale.push({
    'key':i,
    'value':i,
    'text':focusStrings[i]
  });
}

export const difficultyMult = {
    "1" : 0.25,
    "2" : 0.5,
    "3" : 1,
    "4" : 1.5,
    "5" : 2
}
  
export const focusMult = {
    "1" : 0.25,
    "2" : 0.5,
    "3" : 1,
    "4" : 1.5,
    "5" : 2
}

export const initialGoalFormState = { 
    name: '', 
    notes: '', 
    order: 99999
}

export const timeFormats = [{
    'key':0,
    'value':0,
    'text': '12 Hour'
},{
    'key':1,
    'value':1,
    'text':'24 Hour'
}];