import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { API, Analytics } from 'aws-amplify';
import { 
    createTaskFn as createTaskFnMutation,
    updateUserFn as updateUserFnMutation,
    createOutlookDataRequest as createOutlookDataRequestMutation,
    updateOutlookDataRequest as updateOutlookDataRequestMutation
  } from './graphql/mutations';  
import {
    EuiButtonEmpty,
    EuiTourStep,
    EuiFormRow,
    EuiAvatar,
    EuiButton,
    EuiKeyPadMenuItem,
    EuiIcon,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiCallOut,
    EuiFlexGroup,
    EuiFlexItem,
    EuiKeyPadMenu,
    EuiFilePicker,
    EuiDatePickerRange,
    EuiDatePicker,
    EuiSwitch,
    EuiInMemoryTable,
    EuiBadge,
    EuiBadgeGroup,
    EuiFieldText
  } from '@elastic/eui';
import ical from 'ical';
import AdmZip from 'adm-zip';
import { findIana } from 'windows-iana';
import { ProjectSelect } from './Project';
import { Buffer } from 'buffer';
const { parse } = require("arraybuffer-xml-parser");

function OutlookImport({setIsToolsMenuOpen, userData, projects, setProjects, userSettings, setUserSettings, events, setEvents, analyze, keyPad, tourActions, euiTourSteps, tourReducerState, activeCalendar, outlookDataResponse, setOutlookDataResponse}) {
  const [openCloseOutlookImport, setOpenCloseOutlookImport] = useState(false);
  const [parsedEvents,setParsedEvents] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const hasEvents = parsedEvents.length>0;
  const [noNewEvents,setNoNewEvents] = useState(false);
  const [importError,setImportError] = useState("");
  const [selectionMade,setSelectionMade] = useState("");
  const [reRender,setReRender] = useState(0);
  const [calendarType,setCalendarType] = useState("None");
  const [macStartDate, setMacStartDate] = useState(moment());
  const [macEndDate, setMacEndDate] = useState(moment().add(11, 'd'));
  const [disableProjectLink,setDisableProjectLink] = useState(false);
  const [allowCreateTask,setAllowCreateTask] = useState(true);

  useEffect(async () => {
    if(outlookDataResponse) {
      if(outlookDataResponse.length > 0) {
        setOpenCloseOutlookImport(true);
        let cnt = 0;
        let parsedEventsTemp = [];
        for(let i in outlookDataResponse) {
          let duration = moment(outlookDataResponse[i].end,moment.ISO_8601).diff(moment(outlookDataResponse[i].start,moment.ISO_8601),'seconds');
          if(outlookDataResponse[i].categories.length === 0) {
            outlookDataResponse[i].categories = "";
          }
          cnt = await addToParsedEvents(cnt, parsedEventsTemp, "notDate", outlookDataResponse[i].start, outlookDataResponse[i].end, duration, outlookDataResponse[i].id, outlookDataResponse[i].title, outlookDataResponse[i].categories);
          cnt++;
        }
        setParsedEvents(parsedEventsTemp);
        setSelectedItems(parsedEventsTemp);
      }
    }
  }, [outlookDataResponse]);

  useEffect(() => {
    if(activeCalendar.teamid) {
      setDisableProjectLink(true);
    } else {
      setDisableProjectLink(false);
    }
    setAllowCreateTask(activeCalendar.createtask);
  }, [activeCalendar]);

  useEffect(() => {
    let selectionMadeArr = selectionMade.split("^");
    let compareName = selectionMadeArr[0].replace(/\s/g, "").toLowerCase();
    let parsedEventsCopy = [...parsedEvents];
    for(let i in parsedEventsCopy) {
      let matchedProject = false;
      if(parsedEventsCopy[i].categories && userSettings.importAutoCat) {
        let cleanedCategory = parsedEventsCopy[i].categories[0].replace(/\s/g, "").toLowerCase();
        if(cleanedCategory === compareName) {
          parsedEventsCopy[i].projectID = selectionMadeArr[1];
          matchedProject = true;
        }
      }
      if(!matchedProject) {
        let cleanedName = parsedEventsCopy[i].name.replace(/\s/g, "").toLowerCase();
        if(cleanedName === compareName) {
          parsedEventsCopy[i].projectID = selectionMadeArr[1];
        }
      }
    }

    setParsedEvents(parsedEventsCopy);
    let newReRender = reRender;
    newReRender++;
    setReRender(newReRender);
  }, [selectionMade]);

  async function getUserProjectMapping() {
    let outlookProjectSelectionMap = {};
    if(userSettings.outlookProjectSelection) {
      for(let i in userSettings.outlookProjectSelection) {
        let projectSelectionArr = userSettings.outlookProjectSelection[i].split("^");
        if(projectSelectionArr[1] !== "undefined") {
          outlookProjectSelectionMap[projectSelectionArr[0].replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase()] = projectSelectionArr[1]+"^"+projectSelectionArr[2];
        }
      }
    }
    return outlookProjectSelectionMap;
  }

  async function putUserProjectMapping(outlookProjectSelectionMap) {
    let outlookProjectSelectionMapArr = [];
    for(let i in outlookProjectSelectionMap) {
      if(i && i !== '{}') {
        i = i.replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase();
        outlookProjectSelectionMapArr.push(i+"^"+outlookProjectSelectionMap[i]);
      }
    }
    return outlookProjectSelectionMapArr;
  }

  async function createTasks() {
    setIsCreating(true);
    let eventsArr = [];
    let newParsedEvents = [];

    let outlookProjectSelectionMap = await getUserProjectMapping();

    let projectsAssigned = true;
    for(let i in selectedItems) {
      let taskFormData = {...selectedItems[i]};
      if(!taskFormData.projectID || taskFormData.projectID === 0) {
        projectsAssigned = false;
        newParsedEvents.push(taskFormData);
      } else {
        let taskCategories = taskFormData.categories;
        delete taskFormData.end;
        delete taskFormData.id;
        delete taskFormData.projectName;
        delete taskFormData.categories;
        taskFormData.userID = userData.username;
        if(activeCalendar.teamid) {
          taskFormData.teamID=activeCalendar.teamid;
        }
        //let projectIDArr = taskFormData.projectID.split(":");
        //taskFormData.projectID = projectIDArr[0];
        //taskFormData.projectTag = projectIDArr[1];
        taskFormData.calendarID = activeCalendar.value;
        taskFormData.calendarIDgroup = activeCalendar.value;
        taskFormData.calendarIDuserID = activeCalendar.value+""+userData.username;

        try {
          const apiData =  await API.graphql({ query: createTaskFnMutation, variables: { input: taskFormData } });
          apiData.data.createTask = apiData.data.createTaskFn;
          let _event = {
            "title" : apiData.data.createTask.name,
            "task" : apiData.data.createTask,
            "id" : apiData.data.createTask.id,
            "start": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).toDate(),
            "end": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).add(apiData.data.createTask.duration, 'seconds').toDate()
          }
          eventsArr.push(_event);
          
          if(taskCategories) {
            outlookProjectSelectionMap[taskCategories[0].replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase()] = taskFormData.projectID+"^"+taskFormData.projectTag;
          } else {
            outlookProjectSelectionMap[taskFormData.name.replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase()] = taskFormData.projectID+"^"+taskFormData.projectTag;
          }
          
          Analytics.record({ 
            name: 'createTask',
            attributes: {'taskType': "scheduled", 'taskSource': 'OutlookImport'}
          });
        } catch(e) {
          setImportError("Error creating events.")
        }
      }
    }
    let outlookProjectSelectionMapArr = await putUserProjectMapping(outlookProjectSelectionMap);

    API.graphql({ query: updateUserFnMutation, variables: { input: {'outlookProjectSelection':outlookProjectSelectionMapArr}} }).catch((error)=>{});
    setUserSettings({...userSettings, 'outlookProjectSelection':outlookProjectSelectionMapArr});

    if(eventsArr.length) {
      setEvents([ ...events, ...eventsArr ]);
    }

    if(projectsAssigned) {
      setCalendarType("None");
      setOpenCloseOutlookImport(false);
      tourActions.incrementStep();
      setParsedEvents([]);
      setImportError("");
      if(setIsToolsMenuOpen) {
        setIsToolsMenuOpen(false);
      }
      if(setOutlookDataResponse) {
        setOutlookDataResponse([]);
      }
    } else {
      setParsedEvents(newParsedEvents);
      setSelectedItems(newParsedEvents);
      setImportError("Projects were not assigned for some of the Calendar items. Please assign a Project to all items and try again.");
    }
    setIsCreating(false);
  }
  
  async function addToParsedEvents(cnt, parsedEventsTemp, datetype, start, end, duration, uid, summaryValue, categories) {
    let tz = moment.tz.guess();
    if(!moment.isMoment(start)) {
      start = moment.tz(start,tz);
    }

    if(!moment.isMoment(end)) {
      end = moment.tz(end,tz);
    }
    
    if(!events.some(e => e.task.outlookUID === uid)) {
      let outlookProjectSelectionMap = await getUserProjectMapping();

      if(datetype === "date") {
        start = moment(end,moment.ISO_8601).startOf('day').toISOString();
        end = moment(end,moment.ISO_8601).endOf('day').toISOString();
        duration = moment(end,moment.ISO_8601).diff(moment(start,moment.ISO_8601),'seconds');
      }

      summaryValue = summaryValue.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,50);

      let tempObj = {
        'id': cnt,
        'name': summaryValue,
        'description': '',
        'projectID': 0,
        'startDateTime': start,
        'duration': duration,
        'timeSpent': 0,
        'status': 0,
        'notificationStatus': 0,
        'notificationEndStatus': 0,
        'end': end,
        'outlookInvite': false,
        'outlookUID': uid,
        'blocker': false,
        'categories': categories,
        'tags': []
      };

      let setSummaryValue = summaryValue.match(/\((.+)\)/);
      if(categories) {
        let notMatchedCategory = true;
        for(let i in categories) {
          let checkCategory = categories[i].replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase();
          if(notMatchedCategory) {
            let findProject = projects.find(p => {
              let foundProject = false;
              if(!p.altnames) {
                p.altnames = [];
              }
              p.altnames.push(p.text);
              for(let i in p.altnames) {
                if(p.altnames[i].replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase() === checkCategory) {
                  foundProject = true;
                }
              }
              return foundProject;
            });
            if(findProject) {
              tempObj.projectID = findProject.key;
              notMatchedCategory = false;
            } else {
              if(outlookProjectSelectionMap[checkCategory]) {
                let valarr = outlookProjectSelectionMap[checkCategory].split("^");
                let findProject2 = projects.find(p => {
                  if(p.key === valarr[0]) {
                    return true;
                  }
                });
                if(findProject2) {
                  tempObj.projectID = valarr[0];
                  notMatchedCategory = false;
                }
              }
            }
          }
          if(checkCategory === "productivityblocker") {
            tempObj.blocker = true;
          }
          let regexTagCategory = checkCategory.match(/#([A-Za-z]{1}[A-Za-z0-9]+)/);
          if(regexTagCategory) {
            if(!tempObj.tags) {
              tempObj.tags = [];
            }
            tempObj.tags.push(regexTagCategory[1]);
            tempObj.tags = [...new Set(tempObj.tags)];
          }
        }
      } else if(outlookProjectSelectionMap[summaryValue.replace(/[\^ ]/g, "").toLowerCase()]) {
        let valarr = outlookProjectSelectionMap[summaryValue.replace(/[\^ ]/g, "").toLowerCase()].split("^");
        tempObj.projectID = valarr[0];
        if(valarr[1] && valarr[1] !== 'undefined') {
          tempObj.projectTag = valarr[1];
        }
      } else if(setSummaryValue) {
        let findProject = projects.find(p => p.text.replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase() === setSummaryValue[1].replace(/[\^ ]/g, "").toLowerCase());
        if(findProject) {
          tempObj.projectID = findProject.key;
        } else {
          let findTag = projects.find(p => {
            for(let i in p.tags) {
              if(p.tags[i].replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase() === setSummaryValue[1].replace(/[\^ ]/g, "").toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if(findTag) {
            tempObj.projectID = findTag.key;
            tempObj.projectTag = findTag.tags.find(t => t.replace(/[^a-zA-Z0-9.:_\-',]/g, '').substring(0,50).toLowerCase() === setSummaryValue[1].replace(/[\^ ]/g, "").toLowerCase());
          }
        }
      }

      if(analyze) {
        tempObj.status = 2;
        tempObj.timeSpent = duration;
        tempObj.difficulty = 3;
        tempObj.focus = 3;
      }
      parsedEventsTemp.push(tempObj);

      cnt++;
    }
    return cnt;
  }

  const onChange = (files) => {
    setIsCreating(true);
    setNoNewEvents(false);
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      let parsedEventsJSON = [];
      if (calendarType === "Windows") {
        //const parsedEventsAPI = await API.graphql({ query: parseICSFile, variables: { input: event.target.result } });
        parsedEventsJSON = ical.parseICS(event.target.result);
      } else if(calendarType === "MAC") {
        const fileBufferArray = Buffer.from(event.target.result);
        //const fileBufferArray = new Uint8Array(event.target.result);
        
        const zip = new AdmZip(fileBufferArray);

        const zipEntries = zip.getEntries();
        let calendarData;

        for(let i in zipEntries) {
          if(zipEntries[i].entryName.match(/Accounts\/.+@amazon\.\w+\/Calendar\/Calendar.xml/)) {
            calendarData = zipEntries[i].getData();
          }
        }
        
        const doc = parse(calendarData);
        const nodes = doc.appointments.appointment;
        
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i] === null) {
            continue;
          }
          let appt = {};

          for (let j in nodes[i]) {
            if (nodes[i][j] === null) {
              continue;
            } 
            let value = nodes[i][j]["#text"];
            if(value) {
              value = value.toString();
            }
            switch (j) {
              case 'OPFCalendarEventCopySummary':
                appt['summary'] = {};
                appt['summary'].val = value;
                break;
              case 'OPFCalendarEventCopyStartTime':
                appt['index'] = moment(value).format('x');
                appt['start'] = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss');
                break;
              case 'OPFCalendarEventCopyEndTime':
                appt['end'] = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss');
                break;
              case 'OPFCalendarEventGetAcceptStatus':
                if(value === "1") {
                  appt['MICROSOFT-CDO-BUSYSTATUS'] = "BUSY"; 
                } else {
                  appt['MICROSOFT-CDO-BUSYSTATUS'] = "FREE";  
                } 
                break;
              case 'OPFCalendarEventCopyOrganizer':
                appt['organizer'] = {};
                appt['organizer'].val = value;
                break;
              case 'OPFCalendarEventCopyDescriptionPlain':
                appt['Description'] = value;
                break;
              case 'OPFCalendarEventCopyUUID':
                appt['uid'] = value;
                break;
              case 'OPFCalendarEventCopyPrimaryCategory':
                let categories = [];
                categories.push(nodes[i][j]["OPFCategoryCopyName"]["#text"]);
                appt['categories'] = categories;
                break;
              case 'OPFCalendarEventIsRecurring':
                if(value === "1") {
                  appt['recurrance'] = true;
                } else {
                  appt['recurrance'] = false;
                }
                break;
              
              case 'OPFCalendarEventCopyRecurrence':
                for(let k in nodes[i][j]) {
                  if (nodes[i][j][k] === null) {
                    continue;
                  } 

                  let value2 = nodes[i][j][k]["#text"]
                  if(value2) {
                    value2 = value2.toString();
                  }

                  switch (k) {
                    case 'OPFRecurrenceCopyStartDate':
                      appt['recurranceStartDate'] = moment.utc(value2, 'YYYY-MM-DDTHH:mm:ss');
                      break;  
                    case 'OPFRecurrenceCopyEndDate':
                      appt['recurranceEndDate'] = moment.utc(value2, 'YYYY-MM-DDTHH:mm:ss');
                      break;
                    case 'OPFRecurrenceHasEndDate':
                      if(value2 === "1") {
                        appt['recurranceHasEndDate'] = true;
                      } else {
                        appt['recurranceHasEndDate'] = false;
                      }
                      break;
                    case 'OPFRecurrencePattern':
                      for(let l in nodes[i][j][k]) {
                        if (nodes[i][j][k][l] === null) {
                          continue;
                        } 
                        let value3 = nodes[i][j][k][l]["#text"];
                        if(value3) {
                          value3 = value3.toString();
                        }
                        switch (l) {
                          case 'OPFRecurrencePatternDaysOfWeek':
                            for(let m in nodes[i][j][k][l]) {
                              if(typeof nodes[i][j][k][l][m] === "undefined" || nodes[i][j][k][l][m] === null) {
                                continue;
                              }
                              if(nodes[i][j][k][l][m]["#text"] === 1) {
                                if(!appt['DaysOfWeek']) {
                                  appt['DaysOfWeek'] = [];
                                }
                                let dayName = m;
                                let dayNum=0;
                                switch(dayName) {
                                  case "sunday":
                                    break;
                                  case "monday":
                                    dayNum=1;
                                    break;
                                  case "tuesday":
                                    dayNum=2;
                                    break;
                                  case "wednesday":
                                    dayNum=3;
                                    break;
                                  case "thursday":
                                    dayNum=4;
                                    break;
                                  case "friday":
                                    dayNum=5;
                                    break;
                                  case "saturday":
                                    dayNum=6;
                                    break;
                                  case "allDays":
                                    dayNum=7;
                                    break;
                                  case "weekdays":
                                    dayNum=8;
                                    break;
                                  case "weekenddays":
                                    dayNum=9;
                                    break;  
                                  default:
                                    break;
                                }
                                if(dayNum < 7) {
                                  appt['DaysOfWeek'].push(dayNum);
                                } else {
                                  if(dayNum === 7) {
                                    for(let g=0;g<7;g++) {
                                      appt['DaysOfWeek'].push(g);
                                    }
                                  } else if(dayNum === 8) {
                                    for(let g=1;g<6;g++) {
                                      appt['DaysOfWeek'].push(g);
                                    }
                                  } else if(dayNum === 9) {
                                      appt['DaysOfWeek'].push(0);
                                      appt['DaysOfWeek'].push(6);
                                  }
                                }
                              }
                            }
                            break;
                          case 'OPFRecurrencePatternInterval':
                            appt['PatternInterval'] = value3;
                            break;
                          case 'OPFRecurrencePatternType':
                            appt['PatternType'] = value3;
                            break;
                          case 'OPFRecurrencePatternWeek':
                            appt['PatternWeek'] = value3;
                            break;
                          default:
                            break;
                        } 
                      } 
                      break;
                    default:
                      break;
                  }
                }
                break;
              default:
                break;
            } 
          }
          if(appt['start'].isBetween(macStartDate, macEndDate, undefined, '[]') && !appt['recurrance']) {
            appt.type = "VEVENT";
            appt.start = appt.start.toISOString();
            appt.end = appt.end.toISOString();
            parsedEventsJSON.push(appt);
          } else if(appt['recurrance']){
            let performReccuranceCheck = true;
            if(appt['recurranceHasEndDate']) {
              if(!macStartDate.isBetween(appt['recurranceStartDate'], appt['recurranceEndDate'], undefined, '[]') && !macEndDate.isBetween(appt['recurranceStartDate'], appt['recurranceEndDate'], undefined, '[]')) {
                performReccuranceCheck = false;
              }
            }
            let notOwnPTO = false;
            if(appt.summary) {
              if(appt.organizer && /PTO|OOTO|OOO|holiday|vacation|leave|out of office/ig.test(appt.summary.val)) {
                let organizer=appt.organizer.val;
                if(organizer) {
                  let tempArr = organizer.split("@");
                  organizer = tempArr[0].replaceAll("mailto:","");
                  if(organizer !== userData.username.replaceAll("amazonfederate_","")) {
                    notOwnPTO = true;
                  }
                }
              }
            }

            if(performReccuranceCheck && appt["MICROSOFT-CDO-BUSYSTATUS"] === "BUSY" && !notOwnPTO && !appt.summary.val.includes("Canceled:") && !appt.summary.val.includes("Timeblok:")) {
              let duration = appt['end'].diff(appt['start'],'seconds');
              if(appt['PatternType'] === "OPFRecurrencePatternDaily") {
                
              } else if(appt['PatternType'] === "OPFRecurrencePatternWeekly") {
                let recurranceStartDate = appt['start'].clone().startOf('week').set("hour", appt['start'].hour()).set("minute", appt['start'].minutes());
                while(recurranceStartDate.isBefore(macEndDate)) {
                  for(let i in appt['DaysOfWeek']) {
                    recurranceStartDate.day(appt['DaysOfWeek'][i]);
                    if(recurranceStartDate.isBetween(macStartDate, macEndDate, undefined, '[]')) {
                      let apptCopy = JSON.parse(JSON.stringify(appt));
                      apptCopy.type = "VEVENT";
                      apptCopy.start = recurranceStartDate.toISOString();
                      apptCopy.end = recurranceStartDate.clone().add(duration,'seconds').toISOString();
                      apptCopy.uid = apptCopy.uid+":"+recurranceStartDate.toISOString();
                      parsedEventsJSON.push(apptCopy);
                    }
                  }
                  recurranceStartDate.add(parseInt(appt['PatternInterval']), "w").startOf('week').set("hour", appt['start'].hour()).set("minute", appt['start'].minutes());
                }
              } else if(appt['PatternType'] === "OPFRecurrencePatternRelativeMonthly"){
                let recurranceStartDate = appt['start'].clone().startOf('month').set("hour", appt['start'].hour()).set("minute", appt['start'].minutes());
                if(appt['PatternWeek'] !== "5") {
                  recurranceStartDate.add(((parseInt(appt['PatternWeek']))*7)+1,'d');
                } else {
                  let testMoment = recurranceStartDate.clone();
                  testMoment.add(((parseInt(appt['PatternWeek']))*7)+1,'d');
                  if(recurranceStartDate.month() !== testMoment.month()) {
                    recurranceStartDate.add(((4)*7)+1,'d');
                  }
                }
                while(recurranceStartDate.isBefore(macEndDate)) {
                  for(let i in appt['DaysOfWeek']) {
                    recurranceStartDate.day(appt['DaysOfWeek'][i]);
                    if(recurranceStartDate.isBetween(macStartDate, macEndDate, undefined, '[]')) {
                      let apptCopy = JSON.parse(JSON.stringify(appt));
                      apptCopy.type = "VEVENT";
                      apptCopy.start = recurranceStartDate.toISOString();
                      apptCopy.end = recurranceStartDate.clone().add(duration,'seconds').toISOString();
                      apptCopy.uid = apptCopy.uid+":"+recurranceStartDate.toISOString();
                      parsedEventsJSON.push(apptCopy);
                    }
                  }
                  recurranceStartDate.add(parseInt(appt['PatternInterval']), "months").startOf('month').set("hour", appt['start'].hour()).set("minute", appt['start'].minutes());
                  if(appt['PatternWeek'] !== "5") {
                    recurranceStartDate.add(((parseInt(appt['PatternWeek']))*7)+1,'d');
                  } else {
                    let testMoment = recurranceStartDate.clone();
                    testMoment.add(((parseInt(appt['PatternWeek']))*7)+1,'d');
                    if(recurranceStartDate.month() !== testMoment.month()) {
                      recurranceStartDate.add(((4)*7)+1,'d');
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      let parsedEventsTemp = [];
      let cnt=0;
      let localProjects = [];
      for(let i in parsedEventsJSON) {
        if(parsedEventsJSON[i].type === "VEVENT" && parsedEventsJSON[i].summary) {
          let summaryValue=parsedEventsJSON[i].summary;
          if(parsedEventsJSON[i].summary.val) {
            summaryValue=parsedEventsJSON[i].summary.val;
          } else {
            summaryValue = "";
          }
          let ownTask = false;
          let organizer = "";
          if(parsedEventsJSON[i].organizer) {
            organizer=parsedEventsJSON[i].organizer.val;
          }
          if(organizer) {
            let tempArr = organizer.split("@");
            organizer = tempArr[0].replaceAll("mailto:","");
            if(organizer === userData.username.replaceAll("amazonfederate_","")) {
              ownTask = true;
            }
          } else {
            ownTask = true;
          }

          let notOwnPTO = (/PTO|OOTO|OOO|holiday|vacation|leave|out of office/ig.test(summaryValue) && !ownTask);
          if(parsedEventsJSON[i]["MICROSOFT-CDO-BUSYSTATUS"] === "BUSY" || ownTask || parsedEventsJSON[i].recurrences) {
            if(!summaryValue.includes("Canceled:") && !summaryValue.includes("Timeblok:") && !notOwnPTO) {
              let start = parsedEventsJSON[i].start;
              let end = parsedEventsJSON[i].end;
              let tz = moment.tz.guess();
              if(start.tz) {
                let startTZ = findIana(start.tz);
                let startStr = moment(start).format('YYYY-MM-DD HH:mm');
                start = moment.tz(startStr,'YYYY-MM-DD HH:mm',startTZ[0]).tz(tz);
              }
  
              if(end.tz) {
                let endTZ = findIana(end.tz);
                let endStr = moment(end).format('YYYY-MM-DD HH:mm');
                end = moment.tz(endStr,'YYYY-MM-DD HH:mm',endTZ[0]).tz(tz);
              }
              
              let duration = moment(end,moment.ISO_8601).diff(moment(start,moment.ISO_8601),'seconds');
              if(parsedEventsJSON[i].rrule && !parsedEventsJSON[i].recurrences) {
                let nextStartDate = moment(start,moment.ISO_8601);
                for(let j=0;j<parsedEventsJSON[i].rrule.options.count;j++) {
                  if(parsedEventsJSON[i].rrule.options.freq === 1) {
                    cnt = await addToParsedEvents(cnt, parsedEventsTemp, parsedEventsJSON[i].datetype, nextStartDate.toISOString(), nextStartDate.clone().add(duration, 'seconds').toISOString(), duration, parsedEventsJSON[i].uid+":"+nextStartDate.toISOString(), summaryValue, parsedEventsJSON[i].categories);
                    if(parsedEventsJSON[i].rrule.options.bysetpos && parsedEventsJSON[i].rrule.options.byweekday) {
                      if(parsedEventsJSON[i].rrule.options.bysetpos[0] === -1) {
                        nextStartDate.add(parsedEventsJSON[i].rrule.options.interval,'months').endOf("month").day(parsedEventsJSON[i].rrule.options.byweekday[0]);
                      } else {
                        nextStartDate.add(parsedEventsJSON[i].rrule.options.interval,'months').startOf("month").add(((parsedEventsJSON[i].rrule.options.bysetpos[0]-1)*7)+1,'d').day(parsedEventsJSON[i].rrule.options.byweekday[0]);
                      }
                    } else if(parsedEventsJSON[i].rrule.options.bymonthday) {
                      nextStartDate.add(parsedEventsJSON[i].rrule.options.interval,'months').date(parsedEventsJSON[i].rrule.options.bymonthday[0]);
                    } else {
                      nextStartDate.add(parsedEventsJSON[i].rrule.options.interval,'months');
                    }
                  } else if(parsedEventsJSON[i].rrule.options.freq === 2) {
                    if(parsedEventsJSON[i].rrule.options.byweekday.length > 1) {
                      for(let k in parsedEventsJSON[i].rrule.options.byweekday) {
                        let convertedWeekNumber = parsedEventsJSON[i].rrule.options.byweekday[k]+1;
                        if(convertedWeekNumber === 7) {
                          convertedWeekNumber = 0;
                        }
                        nextStartDate.day(convertedWeekNumber);
                        j++;
                        cnt = await addToParsedEvents(cnt, parsedEventsTemp, parsedEventsJSON[i].datetype, nextStartDate.toISOString(), nextStartDate.clone().add(duration, 'seconds').toISOString(), duration, parsedEventsJSON[i].uid+":"+nextStartDate.toISOString(), summaryValue, parsedEventsJSON[i].categories);
                      }
                      nextStartDate.add(1, 'weeks').startOf('isoWeek');
                    } else {
                      cnt = await addToParsedEvents(cnt, parsedEventsTemp, parsedEventsJSON[i].datetype, nextStartDate.toISOString(), nextStartDate.clone().add(duration, 'seconds').toISOString(), duration, parsedEventsJSON[i].uid+":"+nextStartDate.toISOString(), summaryValue, parsedEventsJSON[i].categories);
                      nextStartDate.add(parsedEventsJSON[i].rrule.options.interval,'w');
                    }
                  }
                }
              } else if(parsedEventsJSON[i].recurrences) {
                for(let j in parsedEventsJSON[i].recurrences) {
                  if(parsedEventsJSON[i].recurrences[j]["MICROSOFT-CDO-BUSYSTATUS"] === "BUSY" || ownTask) {
                    let recurrStart = parsedEventsJSON[i].recurrences[j].start;
                    let recurrEnd = parsedEventsJSON[i].recurrences[j].end;
                    if(recurrStart.tz) {
                      let recurrStartTZ = findIana(recurrStart.tz);
                      let recurrStartStr = moment(recurrStart).format('YYYY-MM-DD HH:mm');
                      recurrStart = moment.tz(recurrStartStr,'YYYY-MM-DD HH:mm',recurrStartTZ[0]).tz(tz);
                    }
                    if(recurrEnd.tz) {
                      let recurrEndTZ = findIana(recurrEnd.tz);
                      let recurrEndStr = moment(recurrEnd).format('YYYY-MM-DD HH:mm');
                      recurrEnd = moment.tz(recurrEndStr,'YYYY-MM-DD HH:mm',recurrEndTZ[0]).tz(tz);
                    }
                    cnt = await addToParsedEvents(cnt, parsedEventsTemp, parsedEventsJSON[i].datetype, recurrStart, recurrEnd, duration, parsedEventsJSON[i].uid+":"+recurrStart, summaryValue, parsedEventsJSON[i].categories);
                  }
                }
              } else {
                cnt = await addToParsedEvents(cnt, parsedEventsTemp, parsedEventsJSON[i].datetype, start, end, duration, parsedEventsJSON[i].uid, summaryValue, parsedEventsJSON[i].categories);
              }
            }
          }
        }
      }

      setProjects([...projects, ...localProjects]);

      parsedEventsTemp.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return moment(a.startDateTime,moment.ISO_8601).diff(moment(b.startDateTime,moment.ISO_8601)) 
      });

      setParsedEvents(parsedEventsTemp);
      setSelectedItems(parsedEventsTemp);
      if(parsedEventsTemp.length === 0) {
        setNoNewEvents(true);
      }
      setIsCreating(false);
    });
    
    if(calendarType==="MAC") {
      reader.readAsArrayBuffer(document.getElementById('filePicker').files[0]);
    } else {
      reader.readAsText(document.getElementById('filePicker').files[0]);
    }
  };

  const actions = [
    {
      render: (item) => {
        return (
          <>
          <EuiFlexGroup>
            <EuiFlexItem style={{maxWidth: 180}}>
              <TagInput item={item}/>
            </EuiFlexItem>
            <EuiFlexItem style={{minWidth: 180}}>
              <ProductivityBlockerSwitch item={item}/>
              <EuiSpacer/>
              <EuiFormRow label="Project">
                <ProjectSelect taskFormData={item} userData={userData} projects={projects} setProjects={setProjects} disableCreate={disableProjectLink} noInitial={true} selectionMade={selectionMade} setSelectionMade={setSelectionMade} reRender={reRender} tourActions={tourActions} tourReducerState={tourReducerState} activeCalendar={activeCalendar}/>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          </>
        );
      },
    }
  ];

  const onSelectionChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const selection = {
    onSelectionChange: onSelectionChange,
    initialSelected: parsedEvents
  };


  return (
    <>
      {keyPad ?
        <EuiKeyPadMenuItem
          label="Outlook Import"
          isDisabled={!allowCreateTask}
          onClick ={()=>{
            setOpenCloseOutlookImport(true);
            tourActions.incrementStep();
          }}
        >
          <EuiIcon type="email" size="l" color="primary"/>
        </EuiKeyPadMenuItem> 
      :
      <>
        {!analyze ? 
        <EuiButtonEmpty isDisabled={!allowCreateTask} onClick ={()=>{
          setOpenCloseOutlookImport(true);
          tourActions.incrementStep();
        }}><EuiAvatar name="OutlookImport" type="space" iconType="email" size="s" />&nbsp;Outlook Import</EuiButtonEmpty> 
        : 
        <EuiButton 
          fill 
          isDisabled={!allowCreateTask}
          onClick ={()=>{
            setOpenCloseOutlookImport(true);
            tourActions.incrementStep();
          }}
        >Outlook Import</EuiButton>
        }
      </>
      }
      {openCloseOutlookImport && <EuiModal
        maxWidth="1300px"
        onClose={()=>{
          setOpenCloseOutlookImport(false); 
          tourActions.incrementStep();
        }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1><EuiAvatar name="OutlookImport" type="space" iconType="email" size="m" />&nbsp;Outlook Import</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {!hasEvents && outlookDataResponse.length === 0 ?
            <>
            {calendarType === "None" ?
              <>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    Choose your Calendar Platform: 
                    <EuiKeyPadMenu>
                      <EuiKeyPadMenuItem label="Windows" onClick ={()=>{
                          setCalendarType("Windows");
                        }}>
                        <EuiIcon type="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" size="xl"/>
                      </EuiKeyPadMenuItem>
                      <EuiKeyPadMenuItem label="MAC" onClick ={()=>{
                          setCalendarType("MAC");
                        }}>
                        <EuiIcon type="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" size="xl"/>
                      </EuiKeyPadMenuItem>
                      <EuiKeyPadMenuItem label="TamperMonkey" onClick ={()=>{
                          setCalendarType("TamperMonkey");
                        }}>
                        <EuiIcon type="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Tampermonkey_logo.svg/240px-Tampermonkey_logo.svg.png" size="xl"/>
                      </EuiKeyPadMenuItem>
                    </EuiKeyPadMenu>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            :
              <>
                {calendarType === "Windows" ?
                  <>
                    <EuiCallOut title="Note" color="warning" iconType="help">
                      <EuiFlexGroup>
                        <EuiFlexItem style={{width: 1200}}>
                          How to export Calendar from Outlook in Windows?
                          <ol>
                            <li>Open your Outlook Calendar.</li>
                            <li>Click on File, Save Calendar.</li>
                            <li>Click on <img alt="More Options" src="moreoptions.png" style={{"width": "125px", "display": "inline", "margin": 0}}/></li>
                            <li>Choose the date range of events you want to import into Timeblok.</li>
                            <li>Set the Detail to Full Detail. Click Ok.</li>
                            <li>Save the ics file to your computer and upload it below.
                            <EuiFilePicker
                                id="filePicker"
                                initialPromptText="Select or drag and drop your ics file"
                                onChange={onChange}
                                display={'default'}
                                aria-label="Use aria labels when no actual label is in use"
                              />
                            </li>
                          </ol>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <img alt="Outlook Details" src="outlookdetails.png" style={{"width": "450px"}}/>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiCallOut>
                    {noNewEvents && <EuiCallOut title="No Events Found" color="warning" iconType="help">
                      <p>
                        New events were not found in the import. Try importing again.
                      </p>
                    </EuiCallOut>}
                  </>
                :
                  <>
                  {calendarType === "MAC" ?
                    <>
                      <EuiCallOut title="Note" color="warning" iconType="help">
                        <EuiFlexGroup>
                          <EuiFlexItem style={{width: 1200}}>
                            How to export Calendar from Outlook in MAC?
                            <ol>
                              <li>Open your Outlook Calendar.</li>
                              <li>On the Tools tab, choose Export.</li>
                              <li>In the Export to Archive File (.olm) box, check the Calendar type, and choose Continue. </li>
                              <li>In the Save As box, under Favorites, choose the Downloads folder, and click Save.</li>
                              <li>Once your data has been exported, you'll get a notification. Choose Finish.</li>
                              <li>
                                Choose a date range for your calendar events below.
                                <EuiDatePickerRange
                                  isInvalid={macStartDate > macEndDate}
                                  startDateControl={
                                    <EuiDatePicker
                                      selected={macStartDate}
                                      onChange={(date) => date && setMacStartDate(date)}
                                      startDate={macStartDate}
                                      endDate={macEndDate}
                                      aria-label="Start date"
                                    />
                                  }
                                  endDateControl={
                                    <EuiDatePicker
                                      selected={macEndDate}
                                      onChange={(date) => date && setMacEndDate(date)}
                                      startDate={macStartDate}
                                      endDate={macEndDate}
                                      aria-label="End date"
                                    />
                                  }
                                />
                              </li>
                              <li>Upload the olm file below.
                                <EuiFilePicker
                                  id="filePicker"
                                  initialPromptText="Select or drag and drop your olm file"
                                  onChange={onChange}
                                  display={'default'}
                                  aria-label="Use aria labels when no actual label is in use"
                                />
                              </li>
                              
                            </ol>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <img alt="Outlook Details" src="https://support.content.office.net/en-us/media/02d32fcd-89d5-4779-90df-c078c9f38441.png" style={{"width": "450px"}}/>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiCallOut>
                      {noNewEvents && <EuiCallOut title="No Events Found" color="warning" iconType="help">
                        <p>
                          New events were not found in the import. Try importing again.
                        </p>
                      </EuiCallOut>}
                    </> 
                  : 
                    <>
                    <EuiCallOut title="Note" color="warning" iconType="help">
                        <EuiFlexGroup>
                          <EuiFlexItem style={{width: 1200}}>
                            How to install the TamperMonkey TimeBlok Outlook Integration userscript?
                            <ol>
                              <li>The following steps will only need to done once.</li>
                              <li>Install the TamperMonkey plugin. <a href="https://www.tampermonkey.net/">Click here for instructions.</a></li>
                              <li>Install the TamperMonkey TimeBlok Outlook Integration userscript by <a href="TimeBlokOutlookintegration.user.js">clicking here.</a> </li>
                              <li>After installing the script, you will need to select a date range and click on the Download Outlook Events below. <u>The first time the script runs it will ask to allow access to two websites. Ensure you click on Always Allow for each website. See the screenshot for details.</u></li>
                            </ol>

                            Choose a date range for your calendar events below.
                            <EuiDatePickerRange
                              isInvalid={macStartDate > macEndDate}
                              startDateControl={
                                <EuiDatePicker
                                  selected={macStartDate}
                                  onChange={(date) => date && setMacStartDate(date)}
                                  startDate={macStartDate}
                                  endDate={macEndDate}
                                  aria-label="Start date"
                                />
                              }
                              endDateControl={
                                <EuiDatePicker
                                  selected={macEndDate}
                                  onChange={(date) => date && setMacEndDate(date)}
                                  startDate={macStartDate}
                                  endDate={macEndDate}
                                  aria-label="End date"
                                />
                              }
                            />
                            <EuiButton
                              fill
                              onClick={async ()=>{
                                try {
                                  const apiData =  await API.graphql({ query: createOutlookDataRequestMutation, variables: { input: {
                                    id: userData.username,
                                    start: macStartDate.toISOString(),
                                    end: macEndDate.toISOString(),
                                    status: 0
                                  } } });
                                  window.location.reload(false);
                                } catch(e) {
                                  const apiData =  await API.graphql({ query: updateOutlookDataRequestMutation, variables: { input: {
                                    id: userData.username,
                                    start: macStartDate.toISOString(),
                                    end: macEndDate.toISOString(),
                                    status: 0
                                  } } });
                                  window.location.reload(false);
                                }
                              }}
                            >
                              Download Outlook Events
                            </EuiButton>
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <img alt="Outlook Details" src="tampermonkey.png" style={{"width": "450px"}}/>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiCallOut>
                    </>
                  }
                  </>
                }
              </> 
            }
            </>
          :
            <>
              <EuiCallOut title="Note" color="warning" iconType="help">
                <p>
                  Below are imported events from your Calendar. You can set their Project and select which events you want to create tasks for. If you don't have a Project for the event, you can create one by pressing the add button next to the Project selection box. (You may not have this button if you are part of a team that doesn't allow personal Projects)
                </p>
                <p>
                  Selecting a project for an event will trigger a auto categorization of all the events. Auto categorization is performed on the following:
                  <ul>
                    <li>Events with the same title</li>
                    <li>Events with the same Outlook category (Disabled by default)</li>
                  </ul>
                </p>
                <EuiSwitch
                  label="Auto Categorization based on Outlook category"
                  checked={userSettings.importAutoCat}
                  onChange={(e) => {
                    API.graphql({ query: updateUserFnMutation, variables: { input: {'importAutoCat':e.target.checked}} }).catch((error)=>{});
                    setUserSettings({...userSettings,"importAutoCat": e.target.checked});
                  }}
                />
              </EuiCallOut>
              <EuiFlexGroup>
                <EuiFlexItem grow={9}></EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiTourStep
                  {...euiTourSteps[1]}
                  ></EuiTourStep>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiInMemoryTable
                tableCaption="Calendar Events Found"
                items={parsedEvents}
                selection={selection}
                isSelectable={true}
                hasActions={true}
                itemId="id"
                columns={[
                  {
                    field: 'name',
                    name: 'Name'
                  },
                  {
                    field: 'startDateTime',
                    name: 'Start',
                    render: (date) => (
                      moment(date,moment.ISO_8601).format("YYYY-MM-DD hh:mm:ss A")
                    )
                  },
                  {
                    field: 'categories',
                    name: 'Outlook Categories',
                    render: (categories) => {
                      if(categories) {
                        return (
                          <EuiBadgeGroup>
                            {categories.map(category => (
                            <EuiBadge>{category}</EuiBadge>
                            ))}
                          </EuiBadgeGroup>
                        );
                      }
                    }
                  },
                  {
                    name: 'Actions',
                    actions,
                    width: '40%' 
                  }
                ]}
              />
            </>
          }
        </EuiModalBody>
        <EuiModalFooter>
          {importError &&
            <EuiCallOut title="Note" color="danger" iconType="alert">
              <p>
                {importError}
              </p>
          </EuiCallOut>
          }
          <EuiButton 
            onClick={()=>{
              setCalendarType("None");
              setOpenCloseOutlookImport(false);
              tourActions.incrementStep();
              setParsedEvents([]);
              setImportError("");
              if(setIsToolsMenuOpen) {
                setIsToolsMenuOpen(false);
              }
              if(setOutlookDataResponse) {
                setOutlookDataResponse([]);
              }
            }} 
            fill
          >
            Cancel
          </EuiButton>
          <EuiButton 
            fill
            isDisabled={!hasEvents}
            isLoading={isCreating}
            onClick={() => {
              createTasks();
            }}
          >
            Import
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>}
    </>
  );
}

function TagInput({item}) {
  const [tagInput, setTagInput] = useState("");

  return (
    <>
      <EuiFormRow label="Tags" helpText="Type in your tag with a space at the end. (i.e. #tag<space>)">
          <EuiFieldText
          onChange={(e) => {
            let regexMatch = e.target.value.match(/#([A-Za-z]{1}[A-Za-z0-9]+) /);
            if(regexMatch) {
              setTagInput("");
              if(!item.tags) {
                item.tags = [];
              }
              item.tags.push(regexMatch[1]);
              item.tags = [...new Set(item.tags)];
            } else {
              setTagInput(e.target.value);
            }
          }}
          placeholder="Tags"
          value={tagInput}
          />
      </EuiFormRow>
      <EuiFlexGroup gutterSize="xs">
        {item.tags ? (item.tags.map((value) => {
          return (<EuiFlexItem grow={false}><EuiBadge onClickAriaLabel="Badge" onClick={(e) => {
            e.preventDefault(); 
            const index = item.tags.indexOf(value);
            item.tags.splice(index, 1);
          }}>{"#"+value}</EuiBadge></EuiFlexItem>)
        })) : (<></>)}
      </EuiFlexGroup>
    </>
  );
}
  
function ProductivityBlockerSwitch({item}) {
  const [blocker, setBlocker] = useState(false);

  useEffect(() => {
    if(item.blocker) {
      setBlocker(item.blocker);
    }
  }, [item]);

  return (
    <>
      <EuiFormRow hasChildLabel={false}>
        <EuiSwitch
          label="Productivity Blocker"
          checked={blocker}
          onChange={(e) => {
            item.blocker = e.target.checked;
            setBlocker(e.target.checked);
          }}
        />
      </EuiFormRow>
    </>
  );
}

  export default OutlookImport;