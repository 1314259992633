/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const joinTeam = /* GraphQL */ `
  mutation JoinTeam($teamID: String, $password: String) {
    joinTeam(teamID: $teamID, password: $password) {
      status
    }
  }
`;
export const leaveTeam = /* GraphQL */ `
  mutation LeaveTeam($teamID: String, $userID: String) {
    leaveTeam(teamID: $teamID, userID: $userID)
  }
`;
export const newOwnerTeam = /* GraphQL */ `
  mutation NewOwnerTeam($teamID: String, $userID: String) {
    newOwnerTeam(teamID: $teamID, userID: $userID)
  }
`;
export const deleteTeamFn = /* GraphQL */ `
  mutation DeleteTeamFn($teamID: String) {
    deleteTeamFn(teamID: $teamID)
  }
`;
export const deleteRecurringTaskFn = /* GraphQL */ `
  mutation DeleteRecurringTaskFn($reccuringTaskID: String, $userID: String) {
    deleteRecurringTaskFn(reccuringTaskID: $reccuringTaskID, userID: $userID)
  }
`;
export const deleteCalendarFn = /* GraphQL */ `
  mutation DeleteCalendarFn($calendarID: String, $newCalendarID: String) {
    deleteCalendarFn(calendarID: $calendarID, newCalendarID: $newCalendarID)
  }
`;
export const createCalendarFn = /* GraphQL */ `
  mutation CreateCalendarFn($input: CreateCalendarFnInput!) {
    createCalendarFn(input: $input) {
      id
      group
      name
      description
      owner
      teamID
      createdAt
      updatedAt
    }
  }
`;
export const updateCalendarFn = /* GraphQL */ `
  mutation UpdateCalendarFn($input: UpdateCalendarFnInput!) {
    updateCalendarFn(input: $input) {
      id
      group
      name
      description
      owner
      teamID
      createdAt
      updatedAt
    }
  }
`;
export const changeShareTeamFn = /* GraphQL */ `
  mutation ChangeShareTeamFn($taskID: String, $shareTeam: String) {
    changeShareTeamFn(taskID: $taskID, shareTeam: $shareTeam)
  }
`;
export const addTeamAdminFn = /* GraphQL */ `
  mutation AddTeamAdminFn($teamAdmins: [String], $teamID: String) {
    addTeamAdminFn(teamAdmins: $teamAdmins, teamID: $teamID)
  }
`;
export const createTaskFn = /* GraphQL */ `
  mutation CreateTaskFn($input: CreateTaskFnInput!) {
    createTaskFn(input: $input) {
      id
      name
      description
      startDateTime
      endDateTime
      duration
      status
      notificationEndStatus
      notificationStatus
      difficulty
      focus
      importance
      urgency
      blocker
      shareTeam
      timeSpent
      userID
      projectID
      projectTag
      project {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          createdAt
          updatedAt
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        createdAt
        updatedAt
      }
      reccuringTaskID
      teamID
      teamAdmins
      outlookInvite
      outlookUID
      calendarID
      calendarIDgroup
      calendarIDuserID
      calendarIDshareTeam
      owner
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskFn = /* GraphQL */ `
  mutation UpdateTaskFn($input: UpdateTaskFnInput!) {
    updateTaskFn(input: $input) {
      id
      name
      description
      startDateTime
      endDateTime
      duration
      status
      notificationEndStatus
      notificationStatus
      difficulty
      focus
      importance
      urgency
      blocker
      shareTeam
      timeSpent
      userID
      projectID
      projectTag
      project {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          createdAt
          updatedAt
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        createdAt
        updatedAt
      }
      reccuringTaskID
      teamID
      teamAdmins
      outlookInvite
      outlookUID
      calendarID
      calendarIDgroup
      calendarIDuserID
      calendarIDshareTeam
      owner
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createTeamFn = /* GraphQL */ `
  mutation CreateTeamFn($input: CreateTeamFnInput!) {
    createTeamFn(input: $input) {
      id
      password
      owner
      allowPersonalProjects
      teamMembers
      calendarID
      teamAdmins
      createdAt
      updatedAt
    }
  }
`;
export const createShareCalendarFn = /* GraphQL */ `
  mutation CreateShareCalendarFn($input: CreateShareCalendarFnInput!) {
    createShareCalendarFn(input: $input) {
      id
      calendarID
      calendar {
        id
        group
        name
        description
        owner
        teamID
        createdAt
        updatedAt
      }
      recipient
      sender
      description
      owner
      teamID
      createdAt
      updatedAt
    }
  }
`;
export const updateUserFn = /* GraphQL */ `
  mutation UpdateUserFn($input: UpdateUserFnInput!) {
    updateUserFn(input: $input) {
      id
      name
      email
      phone
      ftmin
      fvmin
      emailnotify
      phonenotify
      webnotify
      calstarttime
      calendtime
      fcmToken
      calendarInvite
      importAutoCat
      timeformat
      teamID
      firstVisit
      team {
        id
        password
        owner
        allowPersonalProjects
        teamMembers
        calendarID
        teamAdmins
        createdAt
        updatedAt
      }
      outlookProjectSelection
      reportsDateRange
      selectedCalendarID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProjectFn = /* GraphQL */ `
  mutation CreateProjectFn($input: CreateProjectFnInput!) {
    createProjectFn(input: $input) {
      id
      name
      altNames
      userID
      type
      color
      goalID
      goal {
        id
        name
        owner
        order
        notes
        calendarID
        createdAt
        updatedAt
      }
      notes
      teamID
      teamMembers
      tags
      calendarID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectFn = /* GraphQL */ `
  mutation UpdateProjectFn($input: UpdateProjectFnInput!) {
    updateProjectFn(input: $input) {
      id
      name
      altNames
      userID
      type
      color
      goalID
      goal {
        id
        name
        owner
        order
        notes
        calendarID
        createdAt
        updatedAt
      }
      notes
      teamID
      teamMembers
      tags
      calendarID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectJob = /* GraphQL */ `
  mutation DeleteProjectJob($oldProjectID: String, $newProjectID: String) {
    deleteProjectJob(oldProjectID: $oldProjectID, newProjectID: $newProjectID)
  }
`;
export const createGoalFn = /* GraphQL */ `
  mutation CreateGoalFn($input: CreateGoalFnInput!) {
    createGoalFn(input: $input) {
      id
      name
      owner
      order
      notes
      calendarID
      createdAt
      updatedAt
    }
  }
`;
export const updateGoalFn = /* GraphQL */ `
  mutation UpdateGoalFn($input: UpdateGoalFnInput!) {
    updateGoalFn(input: $input) {
      id
      name
      owner
      order
      notes
      calendarID
      createdAt
      updatedAt
    }
  }
`;
export const createOutlookDataRequest = /* GraphQL */ `
  mutation CreateOutlookDataRequest($input: CreateOutlookDataRequestInput!) {
    createOutlookDataRequest(input: $input) {
      id
      start
      end
      status
      response
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOutlookDataRequest = /* GraphQL */ `
  mutation UpdateOutlookDataRequest($input: UpdateOutlookDataRequestInput!) {
    updateOutlookDataRequest(input: $input) {
      id
      start
      end
      status
      response
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeamGroupByDataFn = /* GraphQL */ `
  mutation CreateTeamGroupByDataFn($input: CreateTeamGroupByDataFnInput!) {
    createTeamGroupByDataFn(input: $input) {
      id
      userID
      teamID
      tenure
      level
      manager
      leaderofleader
      teamAdmins
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamGroupByDataFn = /* GraphQL */ `
  mutation UpdateTeamGroupByDataFn($input: UpdateTeamGroupByDataFnInput!) {
    updateTeamGroupByDataFn(input: $input) {
      id
      userID
      teamID
      tenure
      level
      manager
      leaderofleader
      teamAdmins
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      altNames
      userID
      type
      color
      goalID
      goal {
        id
        name
        owner
        order
        notes
        calendarID
        createdAt
        updatedAt
      }
      notes
      teamID
      teamMembers
      tags
      calendarID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      name
      owner
      order
      notes
      calendarID
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      startDateTime
      endDateTime
      duration
      status
      notificationEndStatus
      notificationStatus
      difficulty
      focus
      importance
      urgency
      blocker
      shareTeam
      timeSpent
      userID
      projectID
      projectTag
      project {
        id
        name
        altNames
        userID
        type
        color
        goalID
        goal {
          id
          name
          owner
          order
          notes
          calendarID
          createdAt
          updatedAt
        }
        notes
        teamID
        teamMembers
        tags
        calendarID
        owner
        createdAt
        updatedAt
      }
      reccuringTaskID
      teamID
      teamAdmins
      outlookInvite
      outlookUID
      calendarID
      calendarIDgroup
      calendarIDuserID
      calendarIDshareTeam
      owner
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecurringTask = /* GraphQL */ `
  mutation DeleteRecurringTask(
    $input: DeleteRecurringTaskInput!
    $condition: ModelRecurringTaskConditionInput
  ) {
    deleteRecurringTask(input: $input, condition: $condition) {
      id
      name
      description
      startDateTime
      duration
      userID
      projectID
      nextCreationTime
      recurringTaskType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShareCalendar = /* GraphQL */ `
  mutation DeleteShareCalendar(
    $input: DeleteShareCalendarInput!
    $condition: ModelShareCalendarConditionInput
  ) {
    deleteShareCalendar(input: $input, condition: $condition) {
      id
      calendarID
      calendar {
        id
        group
        name
        description
        owner
        teamID
        createdAt
        updatedAt
      }
      recipient
      sender
      description
      owner
      teamID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOutlookDataRequest = /* GraphQL */ `
  mutation DeleteOutlookDataRequest(
    $input: DeleteOutlookDataRequestInput!
    $condition: ModelOutlookDataRequestConditionInput
  ) {
    deleteOutlookDataRequest(input: $input, condition: $condition) {
      id
      start
      end
      status
      response
      createdAt
      updatedAt
      owner
    }
  }
`;
