import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { API,Analytics } from 'aws-amplify';
import { 
    updateTaskFn as updateTaskFnMutation,
    createTaskFn as createTaskFnMutation,
    deleteTask as deleteTaskMutation,
    changeShareTeamFn as changeShareTeamFnMutation
} from './graphql/mutations';  
import {
    EuiButtonEmpty,
    EuiTourStep,
    EuiPopover,
    EuiFormRow,
    EuiAvatar,
    EuiButton,
    EuiKeyPadMenuItem,
    EuiIcon,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiCallOut,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiKeyPadMenu,
    EuiButtonIcon,
    EuiForm,
    EuiCheckbox,
    EuiSelect,
    EuiDatePicker,
    EuiFieldNumber,
    EuiPortal,
    EuiBadge,
    EuiSwitch,
    EuiWrappingPopover,
    EuiInMemoryTable,
} from '@elastic/eui';
import * as workerTimers from 'worker-timers';  
import { 
    initialTaskFormState, 
    recurringTaskOptions, 
    timeFormatArr,
    durationOptions,
    dfScale,
    fcScale,
    statusStrings,
    defaultProjects  
} from './Constants';
import { useGlobalState } from './GlobalState';
import { CalendarShare } from './Calendar';
import OutlookImport from './OutlookImport';
import { ProjectSelect } from './Project';

var timerInt = null;

export function PopoutEvent({props, userData, events, setEvents, projects, setProjects,activeTask,setActiveTask,userSettings,activeCalendar}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeCDSpent, setTimeCDSpent] = useGlobalState('timeCDSpent');
  const open = Boolean(anchorEl);
  const [appContext, setAppContext] = useGlobalState('appContext');

  useEffect(() => {
    if(props.event.task.status === 4 || (props.event.task.status === 1 && appContext !== "createTask" && appContext !== "onSelectSlot")) {
      document.querySelector("#EventDiv-"+props.event.task.id).children[0].click();
    }
  },[props.event.task,appContext]);

  const handleClick = (event) => {
    if(event.target.children[1]) {
      setAnchorEl(event.target.children[1]);
    } else {
      setAnchorEl(event.target);
    }
    setActiveTask(props.event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div id={"EventDiv-"+props.event.task.id} onClick={handleClick}>
      {props.children}
      </div>
      {anchorEl && <EuiWrappingPopover
      button={anchorEl}
      isOpen={open}
      closePopover={handleClose}>
      <ShowActiveTask userData={userData} activeTask={activeTask} setActiveTask={setActiveTask} events={events} setEvents={setEvents} projects={projects} setProjects={setProjects} timeCDSpent={timeCDSpent} setTimeCDSpent={setTimeCDSpent} userSettings={userSettings} activeCalendar={activeCalendar}/>
      </EuiWrappingPopover>}
    </>
  );
}

export function CreateTask({userData,events,setEvents,projects,setProjects,userSettings,setUserSettings,setActiveMenu,euiTourSteps,tourActions,tourReducerState,activeCalendar,calendars,sharedToMeCalendars,setSharedToMeCalendars,sharedByMeCalendars,setSharedByMeCalendars,outlookDataResponse,setOutlookDataResponse}) {
  const [taskFormData,setTaskFormData] = useState(initialTaskFormState);
  const [startDateTime, setStartDateTime] = useState(moment());
  const [duration, setDuration] = useState("3600");
  const [startNow, setStartNow] = useState(true);
  const [recurringTask, setRecurringTask] = useState("0");
  const [disableProjectLink,setDisableProjectLink] = useState(false);
  const [allowCreateTask,setAllowCreateTask] = useState(true);
  const [timerIntTaskActive, setTimerIntTaskActive] = useGlobalState('timerIntTaskActive');
  const [error,setError] = useState("");
  const [tagInput,setTagInput] = useState("");
  const durationHour = convertDuration(duration,1);
  const durationMinutes = convertDuration(duration,2);

  function convertDuration(durationValue,type) {
    let hours = parseInt(durationValue) / 3600;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if(type === 1) {
      return rhours;
    } else {
      return rminutes;
    }
  }

  useEffect(() => {
    if(activeCalendar.teamid) {
      setDisableProjectLink(true);
    } else {
      setDisableProjectLink(false);
    }
    setAllowCreateTask(activeCalendar.createtask);
  }, [activeCalendar]);

  async function createTask() {
    if (!taskFormData.name) return;
    taskFormData.userID = userData.username;
    delete taskFormData.projectName;
    let creationCount = 1;
    
    /*
    if(recurringTask !== '0') {
      let nextCreationDay = 0;
      if(recurringTask === '1') {
        nextCreationDay = 7;
        creationCount = 5;
      } else if(recurringTask === '2') {
        nextCreationDay = 7;
      } else if(recurringTask === '3') {
        nextCreationDay = 14;
      } else if(recurringTask === '4') {
        nextCreationDay = 30;
      }
      let recurringTaskData = await API.graphql({ query: createRecurringTaskMutation, variables: { input: {'name': taskFormData.name, 'description': '', 'startDateTime': taskFormData.startDateTime, nextCreationTime: moment(taskFormData.startDateTime,moment.ISO_8601).add(nextCreationDay, 'd').toISOString(), 'duration': taskFormData.duration, 'userID': taskFormData.userID, 'projectID': taskFormData.projectID, 'recurringTaskType': recurringTask} } });
      taskFormData.reccuringTaskID = recurringTaskData.data.createRecurringTask.id;
    }
    */
    
    if(activeCalendar.teamid) {
      taskFormData.teamID=activeCalendar.teamid;
    }
    let _event = {};
    let newEvents = [];
    while(creationCount > 0) {
      taskFormData.calendarID = activeCalendar.value;
      taskFormData.calendarIDuserID = activeCalendar.value+""+userData.username;
      taskFormData.calendarIDgroup = activeCalendar.value;
      taskFormData.name = taskFormData.name.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,50);
      taskFormData.description = taskFormData.description.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,180);
      if(timerIntTaskActive.name) {
        taskFormData.status = 0;
      }

      try {
        const apiData =  await API.graphql({ query: createTaskFnMutation, variables: { 'input': taskFormData } });
        apiData.data.createTask = apiData.data.createTaskFn;

        _event = {
          "title" : apiData.data.createTask.name,
          "task" : apiData.data.createTask,
          "id" : apiData.data.createTask.id,
          "start": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).toDate(),
          "end": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).add(apiData.data.createTask.duration, 'seconds').toDate()
        }
        newEvents.push(_event);

        if(recurringTask === '1') {
          let date = moment(taskFormData.startDateTime, moment.ISO_8601);
          date.add(1,'d');
          if(date.day() === 6) {
            date.add(2,'d');
          }
          taskFormData.startDateTime = date.toISOString();
        }
        creationCount--;
        
        setEvents([ ...events, ...newEvents ]);
        
        setStartDateTime(moment());
        if(recurringTask === '0') {
          if(document.querySelector("#EventDiv-"+_event.task.id)) {
            document.querySelector("#EventDiv-"+_event.task.id).children[0].click();
          } else {
            taskFormData.status = 0;
          }
        }
        if(taskFormData.status === 4) {
          Analytics.record({ 
              name: 'createTask',
              attributes: {'taskType': 'startNow', 'taskSource': 'Sidebar'}
          });
        } else {
          Analytics.record({ 
              name: 'createTask',
              attributes: {'taskType': "scheduled", 'taskSource': 'Sidebar'}
          });
        }
        setTaskFormData({...taskFormData, name:'', tags:[]});
        //setTaskFormData({...taskFormData, reccuringTaskID:''});
        setRecurringTask("0");
        setError("");
      } catch(e) {
        creationCount = 0;
        setError("Error creating event.");
      }
    }
  }

  return (
    <EuiFlexGroup>
    <EuiFlexItem style={{ maxWidth: 332 }}>
        <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
            <h3><EuiAvatar name="Quicklinks" type="space" iconType="devToolsApp" size="m" color="#0071c2" />&nbsp;Tools</h3>
        </EuiText>
        <EuiSpacer size='s' />
        <EuiKeyPadMenu>
            <CalendarShare userData={userData} calendars={calendars} sharedToMeCalendars={sharedToMeCalendars} setSharedToMeCalendars={setSharedToMeCalendars} sharedByMeCalendars={sharedByMeCalendars} setSharedByMeCalendars={setSharedByMeCalendars} keyPad={true}/>
                    
            <EuiTourStep
            {...euiTourSteps[0]}
            >
            <OutlookImport userData={userData} projects={projects} setProjects={setProjects} userSettings={userSettings} setUserSettings={setUserSettings} events={events} setEvents={setEvents} keyPad={true} tourActions={tourActions} euiTourSteps={euiTourSteps} tourReducerState={tourReducerState} activeCalendar={activeCalendar} outlookDataResponse={outlookDataResponse} setOutlookDataResponse={setOutlookDataResponse}/>
            </EuiTourStep>
            <EuiKeyPadMenuItem label="Reports"
            onClick ={()=>{
                setActiveMenu("reports");
            }}
            >
            <EuiIcon type="visLine" size="l" color="primary"/>
            </EuiKeyPadMenuItem> 
        </EuiKeyPadMenu>
        </EuiPanel>
        <EuiSpacer/>
        <EuiPanel paddingSize="m" grow={false}>
        <EuiText grow={false} textAlign='center'>
            <h3><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Task</h3>
        </EuiText>
        <EuiSpacer size='s' />
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm>
            <EuiFormRow label="Task name">
            <EuiFieldText
                onChange={e => setTaskFormData({ ...taskFormData, 'name': e.target.value})}
                placeholder="Task name"
                value={taskFormData.name}
            />
            </EuiFormRow>
            <EuiFormRow label='Start task immediately'>
            <EuiCheckbox
              id="startNow"
              checked={startNow}
              isDisabled={timerIntTaskActive.name}
              onChange={(e)=>{
                if(e.target.checked) {
                  taskFormData.status = 4;
                  setStartNow(true);
                } else {
                  taskFormData.status = 0;
                  setStartNow(false);
                  setStartDateTime(moment());
                  setTaskFormData({ ...taskFormData, 'startDateTime': moment().toISOString()});
                }
              }}
            />
            </EuiFormRow>
            {/*
            <EuiFormRow label='Task Recurrence'>
            <EuiSelect
                id="recurringTask"
                options={recurringTaskOptions}
                value={recurringTask}
                onChange={(e) => {
                setRecurringTask(e.target.value.toString());
                if(e.target.value !== 0) {
                    taskFormData.status = 0;
                    setStartNow(false);
                    setStartDateTime(moment());
                    setTaskFormData({ ...taskFormData, 'startDateTime': moment().toISOString()});
                }
                }}
            />
            </EuiFormRow>
            */}
            <EuiFormRow label="Start time">
            <EuiDatePicker
                showTimeSelect
                timeIntervals={15}
                selected={startDateTime}
                disabled={startNow}
                timeFormat={timeFormatArr[userSettings.timeformat]}
                onChange={(e) => {
                setTaskFormData({ ...taskFormData, 'startDateTime': moment(e, "YYYY-MM-DD hh:mm:ss A").toISOString()});
                setStartDateTime(e);
                }}
            />
            </EuiFormRow>
            <EuiFormRow label="Duration">
            <EuiFlexGroup>
                <EuiFlexItem>
                <EuiFormRow label="Hour(s)">
                    <EuiFieldNumber
                    value={durationHour}
                    max={4}
                    onChange={(e) => {
                        let diff = e.target.value - durationHour;
                        let newDuration = parseInt(duration)+(diff*3600);
                        if(newDuration >= 300 && newDuration <= 14400) {
                        taskFormData.duration = newDuration;
                        setDuration(newDuration);
                        }
                    }}
                    />
                </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                <EuiFormRow label="Minute(s)">
                    <EuiFieldNumber
                    value={durationMinutes}
                    max={60}
                    onChange={(e) => {
                        let diff = e.target.value - durationMinutes;
                        let newDuration = parseInt(duration)+(diff*60);
                        if(newDuration >= 300 && newDuration <= 14400) {
                        taskFormData.duration = newDuration;
                        setDuration(newDuration);
                        }
                    }}
                    />
                </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            </EuiFormRow>
            {/*
            <EuiFormRow label="Duration">
            <EuiFieldText
                value={durationString}
                disabled={true}
            />
            </EuiFormRow>
            <EuiFormRow label="Duration Control">
            <EuiRange
                value={duration}
                onChange={(e) => {
                taskFormData.duration = e.target.value;
                setDuration(e.target.value);
                }}
                min={300}
                max={14400}
                step={300}
                showTicks
                ticks={[
                { label: '5m', value: 300 },
                { label: '1h', value: 3600 },
                { label: '2h', value: 7200 },
                { label: '3h', value: 10800 },
                { label: '4h', value: 14400 }
                ]}
            />
            </EuiFormRow>
            */}
            {/*
            <EuiFormRow label="Duration">
            <EuiSelect
                placeholder='Duration'
                options={durationOptions}
                value={duration}
                onChange={(e) => {
                taskFormData.duration = e.target.value;
                setDuration(e.target.value);
                }}
            />
            </EuiFormRow>
            */}
            <EuiFormRow label="Project">
            <ProjectSelect taskFormData={taskFormData} userData={userData} projects={projects} setProjects={setProjects} disableCreate={disableProjectLink} activeCalendar={activeCalendar}/>
            </EuiFormRow>
            <EuiFormRow label="Tags" display="columnCompressed" helpText="Type in your tag with a space at the end. (i.e. #tag<space>)">
                <EuiFieldText
                onChange={(e) => {
                  let regexMatch = e.target.value.match(/#([A-Za-z0-9]+) /);
                  if(regexMatch) {
                    setTagInput("");
                    if(!taskFormData.tags) {
                      taskFormData.tags = [];
                    }
                    taskFormData.tags.push(regexMatch[1]);
                    taskFormData.tags = [...new Set(taskFormData.tags)];
                    setTaskFormData({ ...taskFormData, 'tags': taskFormData.tags});
                  } else {
                    setTagInput(e.target.value);
                  }
                }}
                placeholder="Tags"
                value={tagInput}
                />
            </EuiFormRow>
            <EuiFlexGroup gutterSize="xs">
              {taskFormData.tags ? (taskFormData.tags.map((value) => {
                return (<EuiFlexItem grow={false}><EuiBadge onClickAriaLabel="badge" onClick={(e) => {
                  e.preventDefault(); 
                  const index = taskFormData.tags.indexOf(value);
                  taskFormData.tags.splice(index, 1);
                  setTaskFormData({ ...taskFormData, 'tags': taskFormData.tags});
                }}>{"#"+value}</EuiBadge></EuiFlexItem>)
              })) : (<></>)}
            </EuiFlexGroup>
            <EuiSpacer/>
            <EuiButton fill onClick={createTask} isDisabled={!allowCreateTask}>
            Create Task
            </EuiButton>
        </EuiForm>
        </EuiPanel>
    </EuiFlexItem>
    </EuiFlexGroup>
  );
}
  
export function CreateTaskModal({userData,openCreateTask,setOpenCreateTask,events,setEvents,projects,setProjects,userSettings,activeCalendar}) {
  const initialCreateTaskFormData = { name: '', description: '', projectID: '0', startDateTime: '', duration: '', timeSpent: "0", status: 0, notificationStatus: 0, notificationEndStatus: 0, tags: [], outlookInvite: true };
  const [createTaskFormData, setCreateTaskFormData] = useState(initialCreateTaskFormData);
  const [openCreateTaskDuration, setOpenCreateTaskDuration] = useState("3600");
  const [startDateTime,setStartDateTime] = useState("");
  const [recurringTask, setRecurringTask] = useState("0");
  const [disableProjectLink,setDisableProjectLink] = useState(false);
  const [allowCreateTask,setAllowCreateTask] = useState(true);
  const [appContext, setAppContext] = useGlobalState('appContext');
  const [error,setError] = useState("");
  const durationHour = convertDuration(openCreateTaskDuration,1);
  const durationMinutes = convertDuration(openCreateTaskDuration,2);
  const [tagInput,setTagInput] = useState("");

  function convertDuration(durationValue,type) {
    let hours = parseInt(durationValue) / 3600;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if(type === 1) {
      return rhours;
    } else {
      return rminutes;
    }
  }

  useEffect(() => {
    if(activeCalendar.teamid) {
      setDisableProjectLink(true);
    } else {
      setDisableProjectLink(false);
    }
    setAllowCreateTask(activeCalendar.createtask);
  }, [activeCalendar]);


  const setInitialStates = useCallback(async () => {
    if(openCreateTask) {
      setOpenCreateTaskDuration(openCreateTask.task.duration);  
      setStartDateTime(moment(openCreateTask.task.startDateTime, "YYYY-MM-DD hh:mm:ss A"));  
    }
  }, [openCreateTask]);

  useEffect(() => {
    setInitialStates();
  }, [setInitialStates]);

  async function createTask() {
    let prevAppContext = appContext;
    setAppContext("createTask");
    if (!createTaskFormData.name) return;
    createTaskFormData.userID = userData.username;
    createTaskFormData.startDateTime = startDateTime.toISOString();
    createTaskFormData.duration = openCreateTask.task.duration;
    createTaskFormData.status = openCreateTask.task.status;
    delete createTaskFormData.projectName;

    let creationCount = 1;
    /*
    if(recurringTask !== '0') {
      let nextCreationDay = 0;
      if(recurringTask === '1') {
        nextCreationDay = 7;
        creationCount = 5;
      } else if(recurringTask === '2') {
        nextCreationDay = 7;
      } else if(recurringTask === '3') {
        nextCreationDay = 14;
      } else if(recurringTask === '4') {
        nextCreationDay = 30;
      }
      let recurringTaskData = await API.graphql({ query: createRecurringTaskMutation, variables: { input: {'name': createTaskFormData.name, 'description': '', 'startDateTime': createTaskFormData.startDateTime, nextCreationTime: moment(createTaskFormData.startDateTime,moment.ISO_8601).add(nextCreationDay, 'd').toISOString(), 'duration': createTaskFormData.duration, 'userID': createTaskFormData.userID, 'owner': createTaskFormData.userID, 'projectID': createTaskFormData.projectID, 'recurringTaskType': recurringTask} } });
      createTaskFormData.reccuringTaskID = recurringTaskData.data.createRecurringTask.id;
    }
    */

    if(activeCalendar.teamid) {
      createTaskFormData.teamID=activeCalendar.teamid;
    }

    let _event = {};
    let newEvents = [];
    while(creationCount > 0) {
      createTaskFormData.calendarID = activeCalendar.value;
      createTaskFormData.calendarIDuserID = activeCalendar.value+""+userData.username;
      createTaskFormData.calendarIDgroup = activeCalendar.value;
      createTaskFormData.name = createTaskFormData.name.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,50);
      createTaskFormData.description = createTaskFormData.description.replace(/[^a-zA-Z0-9.:_\-'^, ]/g, '').substring(0,180);
      try {
        const apiData =  await API.graphql({ query: createTaskFnMutation, variables: { input: createTaskFormData } });
        apiData.data.createTask = apiData.data.createTaskFn;
        _event = {
          "title" : apiData.data.createTask.name,
          "task" : apiData.data.createTask,
          "id" : apiData.data.createTask.id,
          "start": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).toDate(),
          "end": moment(apiData.data.createTask.startDateTime, moment.ISO_8601).add(apiData.data.createTask.duration, 'seconds').toDate()
        }
        newEvents.push(_event);
        if(recurringTask === '1') {
          let date = moment(createTaskFormData.startDateTime, moment.ISO_8601);
          date.add(1,'d');
          if(date.day() === 6) {
            date.add(2,'d');
          }
          createTaskFormData.startDateTime = date.toISOString();
        }
        creationCount--;
      
        setEvents([ ...events, ...newEvents ]);
        setCreateTaskFormData(initialCreateTaskFormData);
        if(prevAppContext !== "onSelectSlot") {
          document.querySelector("#EventDiv-"+_event.task.id).children[0].click();
        }
        if(createTaskFormData.status === 4) {
          Analytics.record({ 
            name: 'createTask',
            attributes: {'taskType': 'startNow', 'taskSource': 'Calendar'}
          });
        } else {
          Analytics.record({ 
            name: 'createTask',
            attributes: {'taskType': 'scheduled', 'taskSource': 'Calendar'}
          });
        }
        setRecurringTask("0");
        setOpenCreateTask(null);
        setError("");
      } catch(e) {
        setError("Error creating event.");
        creationCount = 0;
      }
    }
  }

  return (
    <div>
    {openCreateTask &&
        <EuiModal
        onClose={() => setOpenCreateTask(null)}
        >
        <EuiModalHeader>
            <EuiModalHeaderTitle>
            <h1><EuiAvatar name="Create" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Create Task</h1>
            </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
            {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
              <p>
                {error}
              </p>
            </EuiCallOut><EuiSpacer size='s' /></> }
            <EuiForm component="form">
            <EuiFormRow label="Task name" display="columnCompressed">
                <EuiFieldText
                onChange={e => setCreateTaskFormData({ ...createTaskFormData, 'name': e.target.value})}
                placeholder="Task name"
                value={createTaskFormData.name}
                />
            </EuiFormRow>
            {/*}
            <EuiFormRow label='Task Recurrence' display="columnCompressed">
            <EuiSelect
                id="recurringTask"
                options={recurringTaskOptions}
                value={recurringTask}
                onChange={(e) => {
                setRecurringTask(e.target.value.toString());
                }}
            />
            </EuiFormRow>
            */}
            <EuiFormRow label="Start time" display="columnCompressed">
                <EuiDatePicker
                showTimeSelect
                timeIntervals={15}
                selected={startDateTime}
                timeFormat={timeFormatArr[userSettings.timeformat]}
                onChange={(e) => {
                    setCreateTaskFormData({ ...createTaskFormData, 'startDateTime': moment(e, "YYYY-MM-DD hh:mm:ss A").toISOString()});
                    setStartDateTime(e);
                }}
                />
            </EuiFormRow>
            <EuiFormRow label="Duration">
                <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Hour(s)">
                    <EuiFieldNumber
                        value={durationHour}
                        max={4}
                        onChange={(e) => {
                        let diff = e.target.value - durationHour;
                        let newDuration = parseInt(openCreateTaskDuration)+(diff*3600);
                        if(newDuration >= 300 && newDuration <= 14400) {
                            openCreateTask.task.duration = newDuration;
                            setOpenCreateTaskDuration(newDuration);
                        }
                        }}
                    />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label="Minute(s)">
                    <EuiFieldNumber
                        value={durationMinutes}
                        max={60}
                        onChange={(e) => {
                        let diff = e.target.value - durationMinutes;
                        let newDuration = parseInt(openCreateTaskDuration)+(diff*60);
                        if(newDuration >= 300 && newDuration <= 14400) {
                            openCreateTask.task.duration = newDuration;
                            setOpenCreateTaskDuration(newDuration);
                        }
                        }}
                    />
                    </EuiFormRow>
                </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
            {/*
            <EuiFormRow label="Duration" display="columnCompressed">
                <EuiSelect
                placeholder='Duration'
                options={durationOptions}
                value={openCreateTaskDuration}
                onChange={(e) => {
                    openCreateTask.task.duration = e.target.value;
                    setOpenCreateTaskDuration(e.target.value);
                }}
                />
            </EuiFormRow>
            */}
            <EuiFormRow label="Project" display="columnCompressed">
                <ProjectSelect taskFormData={createTaskFormData} userData={userData} projects={projects} setProjects={setProjects} disableCreate={disableProjectLink} activeCalendar={activeCalendar}/>
            </EuiFormRow>
            <EuiFormRow label="Tags" display="columnCompressed" helpText="Type in your tag with a space at the end. (i.e. #tag<space>)">
                <EuiFieldText
                onChange={(e) => {
                  let regexMatch = e.target.value.match(/#([A-Za-z0-9]+) /);
                  if(regexMatch) {
                    setTagInput("");
                    if(!createTaskFormData.tags) {
                      createTaskFormData.tags = [];
                    }
                    createTaskFormData.tags.push(regexMatch[1]);
                    createTaskFormData.tags = [...new Set(createTaskFormData.tags)];
                    setCreateTaskFormData({ ...createTaskFormData, 'tags': createTaskFormData.tags});
                  } else {
                    setTagInput(e.target.value);
                  }
                }}
                placeholder="Tags"
                value={tagInput}
                />
            </EuiFormRow>
            <EuiFlexGroup gutterSize="xs">
              {createTaskFormData.tags ? (createTaskFormData.tags.map((value) => {
                return (<EuiFlexItem grow={false}><EuiBadge onClickAriaLabel="badge" onClick={(e) => {
                  e.preventDefault(); 
                  const index = createTaskFormData.tags.indexOf(value);
                  createTaskFormData.tags.splice(index, 1);
                  setCreateTaskFormData({ ...createTaskFormData, 'tags': createTaskFormData.tags});
                }}>{"#"+value}</EuiBadge></EuiFlexItem>)
              })) : (<></>)}
            </EuiFlexGroup>
            </EuiForm>
            <EuiSpacer/>
        </EuiModalBody>
        <EuiModalFooter>
            <EuiButtonEmpty onClick={() => {
            setOpenCreateTask(null);
            }}>Cancel</EuiButtonEmpty>

            <EuiButton fill
            onClick={() => {
                createTask();
            }}
            isDisabled={!allowCreateTask}
            >
            Create Task
            </EuiButton>
        </EuiModalFooter>
        </EuiModal>
    }
    </div>
  );
}
  
export function StopTaskModal({activeTask,events,setEvents}) {
  const [duration, setDuration] = useState(activeTask.task.duration);
  const [taskDifficulty, setTaskDifficulty] = useState(3);
  const [taskFocus, setTaskFocus] = useState(3);
  const [taskNotes, setTaskNotes] = useState("");
  const [durationChange,setDurationChange] = useState(false);
  const [timeCDSpent, setTimeCDSpent] = useGlobalState('timeCDSpent');
  const [timerIntTaskActive, setTimerIntTaskActive] = useGlobalState('timerIntTaskActive');
  const [openStopTask, setOpenStopTask] = useGlobalState('openStopTask');
  const [error,setError] = useState("");

  async function stopTask(task) { 
    let timeSpent = task.timeSpent;
    if(durationChange || parseInt(timeSpent) === 0) {
      timeSpent = duration;
    }

    if(timerInt) { workerTimers.clearInterval(timerInt); }
    timerInt = null;
    setTimerIntTaskActive({});
    setTimeCDSpent(0);

    try {
      await API.graphql({ query: updateTaskFnMutation, variables: { input: {id: task.id, 'status':2, 'timeSpent': timeSpent, 'difficulty':taskDifficulty, 'focus':taskFocus, 'description':taskNotes}} });
      task.status=2;
      task.timeSpent=timeSpent;
      task.difficulty=taskDifficulty;
      task.description=taskNotes;
      task.focus=taskFocus;

      const newEvents = events.map((chkEvent) => {
        if (chkEvent.task.id === task.id) {
          const updatedTask = {
            ...chkEvent,
            end: moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(timeSpent, 'seconds').toDate(),
            task: task,
          };
          return updatedTask;
        }
        return chkEvent;
      });
      setEvents(newEvents);
      setOpenStopTask(false);
      Analytics.record({ 
        name: 'finishedTask', 
        attributes: {}, 
        metrics: { timeSpent: Number((timeSpent/3600).toFixed(1)) } 
      });
      setError("");
    } catch(e) {
      setError("Error stopping task.");
    }
  }

  return (
    <>
    <EuiModal
        onClose={() => setOpenStopTask(false)}
    >
        <EuiModalHeader>
        <EuiModalHeaderTitle>
            <h1><EuiAvatar name="Stop" type="space" iconType="stop" size="m" color="#BD271E" />&nbsp;Ending Task</h1>
        </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm component="form">
            <EuiFormRow label="Task Notes" display="columnCompressed">
            <EuiFieldText 
                onChange={e => setTaskNotes(e.target.value)}
                value={taskNotes}
            />
            </EuiFormRow>
            <EuiFormRow label="Time Spent" display="columnCompressed">
            <EuiFieldText
                value={new Date(activeTask.task.timeSpent * 1000).toISOString().substr(11, 8)}
            />
            </EuiFormRow>
            <EuiFormRow label="Set your time spent manually." display="columnCompressed">
            <EuiCheckbox
              checked={durationChange}
              onChange={(e)=>{
                setDurationChange(e.target.checked);
              }}
            />
            </EuiFormRow>
            <EuiFormRow label="Time Allocated for Task" display="columnCompressed">
            <EuiSelect
                placeholder='Duration'
                options={durationOptions}
                value={duration}
                disabled={!durationChange}
                onChange={(e) => {
                setDuration(e.target.value);
                }}
            />
            </EuiFormRow>
            <EuiFormRow label="Task Difficulty" display="columnCompressed">
            <EuiSelect 
                placeholder='Task Difficulty'
                options={dfScale}
                value={taskDifficulty}
                onChange={(e) => {
                setTaskDifficulty(e.target.value);
                }}
            />
            </EuiFormRow>
            <EuiFormRow label="Task Focus" display="columnCompressed">
            <EuiSelect
                placeholder='Task Focus'
                options={fcScale}
                value={taskFocus}
                onChange={(e) => {
                setTaskFocus(e.target.value);
                }}
            />
            </EuiFormRow>
        </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
        <EuiButtonEmpty onClick={() => {
            setOpenStopTask(false);
        }}>Cancel</EuiButtonEmpty>
        <EuiButton
            fill
            color="danger"
            onClick={() => {
            stopTask(activeTask.task);
            }}
        >
            End Task
        </EuiButton>
        </EuiModalFooter>
    </EuiModal>
    </>
  );
}
  
export function EditTaskModal({activeTask,setEditTask,events,setEvents,userData,projects,setProjects,userSettings,activeCalendar}) {
  const [startDateTime, setStartDateTime] = useState(moment());
  const [openEditTaskDuration, setOpenEditTaskDuration] = useState({});
  const [editTaskFormData, setEditTaskFormData] = useState({});
  const [localDurationOptions,setLocalDurationOptions] = useState([...durationOptions]);
  const [tagInput,setTagInput] = useState("");
  const [error,setError] = useState("");

  useEffect(() => {
    if(activeTask.task.timeSpent) {
      if(typeof localDurationOptions.find( ({ value }) => value === activeTask.task.timeSpent.toString() ) === 'undefined') {
        let date = new Date(0);
        date.setSeconds(activeTask.task.timeSpent);
        localDurationOptions.push({ key: activeTask.task.timeSpent.toString(), value: activeTask.task.timeSpent.toString(), text: date.toISOString().substr(11, 8) });
        setLocalDurationOptions(localDurationOptions);
      }
      setOpenEditTaskDuration(activeTask.task.timeSpent);
    } else {
      setOpenEditTaskDuration(activeTask.task.duration);
    }
    setStartDateTime(moment(activeTask.task.startDateTime,"YYYY-MM-DDThh:mm:ss.sssZ"));
    setEditTaskFormData(activeTask.task);
  },[activeTask,localDurationOptions]);

  async function submitEditTask() {
    activeTask.title = editTaskFormData.name;
    activeTask.start = moment(editTaskFormData.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate();
    activeTask.end = moment(editTaskFormData.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(openEditTaskDuration, 'seconds').toDate();
    editTaskFormData.name = editTaskFormData.name.replace(/[^a-zA-Z0-9.:_\- ]/g, '').substring(0,50);
    editTaskFormData.description = editTaskFormData.description.replace(/[^a-zA-Z0-9.:_\- ]/g, '').substring(0,180);
    if(editTaskFormData.projectTag == null) {
      editTaskFormData.projectTag = "";
    }
    if(editTaskFormData.tags == null) {
      editTaskFormData.tags = [];
    }
    let inputVar = {id: activeTask.task.id, 'name': editTaskFormData.name, 'description': editTaskFormData.description, 'startDateTime': editTaskFormData.startDateTime, 'duration': openEditTaskDuration, 'projectID': editTaskFormData.projectID, 'projectTag': editTaskFormData.projectTag, 'tags': editTaskFormData.tags};
    if(activeCalendar.teamid) {
      inputVar.teamID = activeCalendar.teamid;
    }
    if(activeTask.task.status === 2) {
      inputVar.timeSpent = openEditTaskDuration;
      inputVar.endDateTime = moment(editTaskFormData.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(openEditTaskDuration, 'seconds').toISOString();
    }
    try {
      let results = await API.graphql({ query: updateTaskFnMutation, variables: { input: inputVar} });
      activeTask.task = results.data.updateTaskFn;
      let newEventsArr = events.filter(obj => obj.task.id !== editTaskFormData.id);
      setEvents([ ...newEventsArr, activeTask ]);
      setError("");
      setEditTask(false);
    } catch(e) {
      setError("Error editing task.");
    }
  }

  return (
    <>
    <EuiModal
        style={{height: '525px'}}
        onClose={() => setEditTask(false)}
    >
        <EuiModalHeader>
        <EuiModalHeaderTitle>
            <h1><EuiAvatar name="Edit" type="space" iconType="documentEdit" size="m" color="#69707D" />&nbsp;Edit Task</h1>
        </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
        {error && <><EuiCallOut title="Error" color="danger" iconType="stop">
          <p>
            {error}
          </p>
        </EuiCallOut><EuiSpacer size='s' /></> }
        <EuiForm component="form">
            <EuiFormRow label="Task name" display="columnCompressed">
              <EuiFieldText
                  onChange={e => {
                  setEditTaskFormData({ ...editTaskFormData, 'name': e.target.value});
                  activeTask.task.name = e.target.value;
                  }}
                  placeholder="Task name"
                  value={editTaskFormData.name}
              />
            </EuiFormRow>
            <EuiFormRow label="Task notes" display="columnCompressed">
              <EuiFieldText
                  onChange={e => {
                  setEditTaskFormData({ ...editTaskFormData, 'description': e.target.value});
                  activeTask.task.description = e.target.value;
                  }}
                  placeholder="Task notes"
                  value={editTaskFormData.description}
              />
            </EuiFormRow>
            <EuiFormRow label="Start time" display="columnCompressed">
              <EuiDatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={startDateTime}
                  timeFormat={timeFormatArr[userSettings.timeformat]}
                  onChange={(e) => {
                    setEditTaskFormData({ ...editTaskFormData, 'startDateTime': e.toISOString()});
                    activeTask.task.startDateTime = e.toISOString();
                    setStartDateTime(e);
                  }}
              />
            </EuiFormRow>
            <EuiFormRow label="Duration" display="columnCompressed">
              <EuiSelect
                placeholder='Duration'
                options={localDurationOptions}
                value={openEditTaskDuration}
                onChange={(e) => {
                  activeTask.task.duration = e.target.value;
                  setOpenEditTaskDuration(e.target.value);
                }}
              />
            </EuiFormRow>
            <EuiFormRow label="Project" display="columnCompressed">
            <ProjectSelect taskFormData={editTaskFormData} userData={userData} projects={projects} setProjects={setProjects} buttonColor="text" activeCalendar={activeCalendar}/>
            </EuiFormRow>
            <EuiFormRow label="Tags" display="columnCompressed" helpText="Type in your tag with a space at the end. (i.e. #tag<space>)">
                <EuiFieldText
                onChange={(e) => {
                  let regexMatch = e.target.value.match(/#([A-Za-z0-9]+) /);
                  if(regexMatch) {
                    setTagInput("");
                    if(!editTaskFormData.tags) {
                      editTaskFormData.tags = [];
                    }
                    editTaskFormData.tags.push(regexMatch[1]);
                    editTaskFormData.tags = [...new Set(editTaskFormData.tags)];
                    setEditTaskFormData({ ...editTaskFormData, 'tags': editTaskFormData.tags});
                  } else {
                    setTagInput(e.target.value);
                  }
                }}
                placeholder="Tags"
                value={tagInput}
                />
            </EuiFormRow>
            <EuiFlexGroup gutterSize="xs">
              {editTaskFormData.tags ? (editTaskFormData.tags.map((value) => {
                return (<EuiFlexItem grow={false}><EuiBadge onClickAriaLabel="badge" onClick={(e) => {
                  e.preventDefault(); 
                  const index = editTaskFormData.tags.indexOf(value);
                  editTaskFormData.tags.splice(index, 1);
                  setEditTaskFormData({ ...editTaskFormData, 'tags': editTaskFormData.tags});
                }}>{"#"+value}</EuiBadge></EuiFlexItem>)
              })) : (<></>)}
            </EuiFlexGroup>
        </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
        <EuiButtonEmpty onClick={() => {
            setEditTask(false);
        }}>Cancel</EuiButtonEmpty>
        <EuiButton
            fill
            color="text"
            onClick={() => {
            submitEditTask();
            }}
        >
        Save
        </EuiButton>
        </EuiModalFooter>
    </EuiModal>
    </>
  );
}

/*
export function DeleteRecurringTaskModal({activeTask, setActiveTask, setTimerIntTaskActive, setTimeCDSpent, setDeleteRecurringTask, events, setEvents}) {
    async function deleteRecurringTask(deleteAllTasks) {
        let id = activeTask.task.id;
        let reccuringTaskID = activeTask.task.reccuringTaskID;
        if(reccuringTaskID && deleteAllTasks === 0) {
          await API.graphql({ query: deleteRecurringTaskMutation, variables: { input: { id: reccuringTaskID } }});
          API.graphql({ query: deleteRecurringTaskFnMutation, variables: {'reccuringTaskID': reccuringTaskID, 'userID': activeTask.task.userID} });
        } else {
          await API.graphql({ query: deleteTaskMutation, variables: { input: { id } }});
        }
        let newEventsArray = events.filter(event => event.task.id !== id);
        if(reccuringTaskID && deleteAllTasks === 0) {
          newEventsArray = newEventsArray.filter(event => event.task.reccuringTaskID !== reccuringTaskID);
        }
        setEvents(newEventsArray);
        setActiveTask([]);
        if(timerInt) { workerTimers.clearInterval(timerInt); }
        timerInt = null;
        setTimerIntTaskActive({});
        setTimeCDSpent(0);
    }

    return (
        <>
        <EuiModal
            onClose={() => setDeleteRecurringTask(false)}
        >
            <EuiModalHeader>
            <EuiModalHeaderTitle>
                <h1><EuiAvatar name="DeleteRecurringTask" type="space" iconType="trash" size="m" color="#BD271E" />&nbsp;Delete Recurring Task</h1>
            </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
            <EuiCallOut title="Note" color="warning" iconType="help">
                <p>
                Do you wish to delete this recurring task and all associated tasks?
                </p>
            </EuiCallOut>
            </EuiModalBody>
            <EuiModalFooter>
            <EuiButtonEmpty onClick={() => {
                setDeleteRecurringTask(false);
                deleteRecurringTask(0);
            }}>Delete all the tasks</EuiButtonEmpty>
            <EuiButtonEmpty onClick={() => {
                setDeleteRecurringTask(false);
                deleteRecurringTask(1);
            }}>Delete just this task</EuiButtonEmpty>
            <EuiButtonEmpty onClick={() => {
                setDeleteRecurringTask(false);
            }}>Cancel</EuiButtonEmpty>
            </EuiModalFooter>
        </EuiModal>
        </>
    );
}
*/
  
export function ActiveTaskButtons({activeTask,setActiveTask,timeCDSpent,setTimeCDSpent,events,setEvents,userData,projects,setProjects,timerIntTaskActive,setTimerIntTaskActive,userSettings,activeCalendar}) {
  const [editTask, setEditTask] = useState(false);
  const [timerIntState,setTimerIntState] = useState(timerInt);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [openStopTask,setOpenStopTask] = useGlobalState('openStopTask');
  const [deleteRecurringTask, setDeleteRecurringTask] = useState(false);
  const [appContext, setAppContext] = useGlobalState('appContext');

  const startTask = useCallback(async () => {
    setAppContext("startTask");
    if(activeTask.task.status === 4 || activeTask.task.status === 0 || activeTask.task.status === 3) {
      let now = moment();
      let statusBefore = activeTask.task.status;
      activeTask.task.status = 1;
      setTimeCDSpent(activeTask.task.timeSpent);
      if(statusBefore === 4 || statusBefore === 0) {
        API.graphql({ query: updateTaskFnMutation, variables: { input: {id: activeTask.task.id, 'status': 1, 'startDateTime': now.toISOString(), 'endDateTime': now.clone().add(activeTask.task.duration, 'seconds').toISOString()}} }).catch((error)=>{});
        activeTask.task.startDateTime = now.toISOString();
      }
      const newEvents = events.map((chkEvent) => {
        if (chkEvent.task.id === activeTask.task.id && (statusBefore === 4 || statusBefore === 0)) {
          const updatedTask = {
            ...chkEvent,
            "start": moment(activeTask.task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate(),
            "end": moment(activeTask.task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(activeTask.task.duration, 'seconds').toDate(),
            "task": activeTask.task,
          };
          return updatedTask;
        }
        return chkEvent;
      });
      setEvents(newEvents);
    } 
    if(timerInt === null) {
      timerInt = workerTimers.setInterval(()=>{
        if(activeTask.task.timeSpent < activeTask.task.duration) {
          activeTask.task.timeSpent++;
          if(activeTask.task.timeSpent%59 === 0 || activeTask.task.timeSpent%30 === 0) {
            API.graphql({ query: updateTaskFnMutation, variables: { input: {id: activeTask.task.id, 'timeSpent': activeTask.task.timeSpent}} }).catch((error)=>{});
          }
          setTimeCDSpent(activeTask.task.timeSpent);
        } else {
          if(timerInt !== null) {
            if(timerInt) { workerTimers.clearInterval(timerInt); }
            timerInt = null;
          }
          activeTask.task.timeSpent = activeTask.task.duration;
          setTimeCDSpent(activeTask.task.timeSpent);
          API.graphql({ query: updateTaskFnMutation, variables: { input: {id: activeTask.task.id, 'timeSpent': activeTask.task.timeSpent}} }).catch((error)=>{});
          document.querySelector("#EventDiv-"+activeTask.task.id).children[0].click();
        }
      },1000);
      setTimerIntTaskActive(activeTask.task);
    }
    setTimerIntState(timerInt);
    setTimeCDSpent(activeTask.task.timeSpent);
  },[activeTask.task,events,setEvents,setTimeCDSpent,setTimerIntTaskActive,setAppContext]);

  const stopTask = useCallback(async (task) => {
    let timeSpent = timeCDSpent;

    if(timerInt) { workerTimers.clearInterval(timerInt); }
    timerInt = null;
    setTimerIntTaskActive({});
    setTimeCDSpent(0);

    if(parseInt(timeSpent) === 0 && task.status !== 3) {
      timeSpent = task.duration;
    }

    try {
      await API.graphql({ query: updateTaskFnMutation, variables: { input: {id: task.id, 'status':2, 'timeSpent': timeSpent, 'duration': timeSpent, 'difficulty':3, 'focus':3, 'description':""}} });
      task.status=2;
      task.timeSpent=timeSpent;
      task.difficulty=3;
      task.description="";
      task.focus=3;
        
      const newEvents = events.map((chkEvent) => {
        if (chkEvent.task.id === task.id) {
          const updatedTask = {
            ...chkEvent,
            end: moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(timeSpent, 'seconds').toDate(),
            task: task,
          };
          return updatedTask;
        }
        return chkEvent;
      });
      setEvents(newEvents);
      
      Analytics.record({ 
        name: 'finishedTask', 
        attributes: {}, 
        metrics: { timeSpent: Number((timeSpent/3600).toFixed(1)) } 
      });
      setOpenStopTask(true);
    } catch(e) {}
  },[events,setEvents,setOpenStopTask,setTimeCDSpent,setTimerIntTaskActive,timeCDSpent]);

  useEffect(() => {
    if(activeTask.task.status === 1 || activeTask.task.status === 4) {
      startTask();
    }
  },[startTask,activeTask]);

  useEffect(() => {
    if((activeTask.task.duration === timeCDSpent && activeTask.task.status !== 2) || activeTask.endNow) {
      stopTask(activeTask.task)
    }
  },[activeTask,timeCDSpent,stopTask]);

  async function pauseTask() {
    try {
      await API.graphql({ query: updateTaskFnMutation, variables: { input: {id: activeTask.task.id, 'status': 3, 'timeSpent': activeTask.task.timeSpent}} }).catch((error)=>{});
      activeTask.task.status = 3;
      const newEvents = events.map((chkEvent) => {
        if (chkEvent.task.id === activeTask.task.id) {
          const updatedTask = {
            ...chkEvent,
            "task": activeTask.task,
          };
          return updatedTask;
        }
        return chkEvent;
      });
      setEvents(newEvents);
      if(timerInt) { workerTimers.clearInterval(timerInt); }
      timerInt = null;
      setTimerIntTaskActive({});
      setTimerIntState(null);
      setTimeCDSpent(activeTask.task.timeSpent);
    } catch(e) {}
  }

  async function deleteTask({ id }) {
    if(activeTask.task.reccuringTaskID) {
      setDeleteRecurringTask(true);
    } else {
      const newEventsArray = events.filter(event => event.task.id !== id);
      setEvents(newEventsArray);
      await API.graphql({ query: deleteTaskMutation, variables: { input: { id } }});
      setActiveTask([]);
      if(timerInt) { workerTimers.clearInterval(timerInt); }
      timerInt = null;
      setTimerIntTaskActive({});
      setTimeCDSpent(0);
    }
  }

    return (
        <>
        <EuiButtonIcon 
            color="success"
            aria-label="Play"
            isDisabled={activeTask.task.status === 2 || Boolean(timerIntState) || (timerIntTaskActive.id !== activeTask.task.id && typeof timerIntTaskActive.id !== 'undefined')} 
            iconType="play"
            size="m"
            display="fill"
            onClick={() => startTask()}/>
        &nbsp;
        <EuiButtonIcon 
            color="warning"
            aria-label="Pause"
            isDisabled={activeTask.task.status === 2 || Boolean(!timerIntState) || (timerIntTaskActive.id !== activeTask.task.id && typeof timerIntTaskActive.id !== 'undefined')} 
            iconType="pause"
            size="m"
            display="fill"
            onClick={() => pauseTask()}/>
        &nbsp;
        <EuiButtonIcon 
            color="danger"
            aria-label="Stop"
            iconType="stop"
            size="m"
            display="fill"
            isDisabled={activeTask.task.status === 2}
            onClick={() => {
            stopTask(activeTask.task);
            }}
        />
        &nbsp;
        <EuiButtonIcon 
            color="text"
            aria-label="Edit"
            iconType="documentEdit"
            size="m"
            display="fill"
            isDisabled={activeTask.task.status === 1}
            onClick={() => {
                setEditTask(true);
            }}
        />
        {editTask && <EditTaskModal
            activeTask={activeTask} 
            setEditTask={setEditTask}
            events={events}
            setEvents={setEvents}
            userData={userData}
            projects={projects}
            setProjects={setProjects}
            userSettings={userSettings}
            activeCalendar={activeCalendar}
        />}  
        &nbsp;
        <EuiButtonIcon 
            color="text"
            aria-label="Trash"
            iconType="trash"
            size="m"
            display="fill"
            onClick={() => deleteTask(activeTask.task)}/>
        &nbsp;
        {/*deleteRecurringTask && <DeleteRecurringTaskModal
            activeTask={activeTask} 
            setDeleteRecurringTask={setDeleteRecurringTask}
            events={events}
            setEvents={setEvents}
            setActiveTask = {setActiveTask}
            setTimerIntTaskActive = {setTimerIntTaskActive}
            setTimeCDSpent = {setTimeCDSpent}
        />*/}
        {activeTask.task.description && <EuiPopover
            isOpen={isNotesOpen}
            closePopover={()=>{
                setIsNotesOpen(false);
            }}
            button={<EuiButtonIcon
                aria-label="Notes"
                iconType="document"
                size="m"
                display="fill"
                onClick={()=>{
                    if(!isNotesOpen) {
                        setIsNotesOpen(true);
                    } else {
                        setIsNotesOpen(false);
                    }
                }}
            />}
            >
            {activeTask.task.description}
        </EuiPopover>}
        </>
    );
}
  
export function ShowActiveTask({userData,activeTask,setActiveTask,events,setEvents,projects,setProjects,timeCDSpent,setTimeCDSpent,userSettings,activeCalendar}) {
  const [timerIntTaskActive, setTimerIntTaskActive] = useGlobalState('timerIntTaskActive');
  const [blocker,setBlocker] = useState(activeTask.task.blocker);
  const [shareTeam,setShareTeam] = useState(activeTask.task.shareTeam);

  async function handleShareTeam(e) {
    let setValue = 0;
    if(e.target.checked) {
      setValue = 1;
      if(userSettings.team) {
        let teamObj = JSON.parse(userSettings.team);
        if(teamObj.owner === userData.username) {
          setValue = 2;
        }
      }
    }
    activeTask.task.shareTeam = setValue;
    activeTask.task.calendarIDshareTeam = activeTask.task.calendarID + "" + setValue;
    setShareTeam(setValue);
    let id = activeTask.task.id;
    try {
      await API.graphql({ query: changeShareTeamFnMutation, variables: {'taskID': id, 'shareTeam': setValue.toString()} });
    } catch(e) {

    }
  }

  useEffect(() => {
    if(activeTask.task.id === timerIntTaskActive.id) {
      setTimeCDSpent(activeTask.task.timeSpent);
    } 
  },[activeTask,setTimeCDSpent,timerIntTaskActive]);

  return (
    <div>
    <EuiPanel paddingSize="m" style={{ textAlign: 'center' }}>
        <EuiText grow={false}>
        <h3><EuiAvatar name="Task" type="space" iconType="gear" size="m" color="#69707D" />&nbsp;{activeTask.task.name}</h3>
        </EuiText>
        <EuiText grow={false}>
        <h3>
        {activeTask.task.status === 2 ? 
            new Date(activeTask.task.timeSpent * 1000).toISOString().substr(11, 8) : 
            ( activeTask.task.status === 1 ?
            new Date((activeTask.task.duration - timeCDSpent) * 1000).toISOString().substr(11, 8) :
            new Date((activeTask.task.duration - activeTask.task.timeSpent) * 1000).toISOString().substr(11, 8)
            )
        }
        </h3>
        </EuiText>
        <EuiBadge>
        {statusStrings[activeTask.task.status]}
        </EuiBadge>
        <EuiBadge>
        {defaultProjects[activeTask.task.projectID] ? defaultProjects[activeTask.task.projectID] : ( activeTask.task.project.name )}
        </EuiBadge>
        {activeTask.task.projectTag &&
        <EuiBadge>
            {activeTask.task.projectTag}
        </EuiBadge>
        }
        <EuiText grow={false}>
          {activeTask.task.tags ? (activeTask.task.tags.map((value) => {
            return (<EuiBadge>{"#"+value}</EuiBadge>)
          })) : (<></>)}
        </EuiText>
        {activeTask.task.owner === userData.username ? 
        <>
            <EuiSpacer/>
            <EuiSwitch
            label="Productivity Blocker"
            checked={blocker ? blocker : false}
            onChange={(e) => {
                activeTask.task.blocker = e.target.checked;
                setBlocker(e.target.checked);
                API.graphql({ query: updateTaskFnMutation, variables: { input: {id: activeTask.task.id, 'blocker':e.target.checked}} }).catch((error)=>{});
            }}
            />
            <EuiSpacer/>
            {activeCalendar.teamid && <><EuiSwitch
            label="Share with team"
            checked={shareTeam ? shareTeam >= 1 : false}
            onChange={handleShareTeam}
            /><EuiSpacer/></>}
            <ActiveTaskButtons 
            activeTask={activeTask} 
            setActiveTask={setActiveTask}
            timeCDSpent={timeCDSpent}
            setTimeCDSpent={setTimeCDSpent} 
            events={events} 
            setEvents={setEvents}
            userData={userData}
            projects={projects}
            setProjects={setProjects}
            timerIntTaskActive={timerIntTaskActive}
            setTimerIntTaskActive={setTimerIntTaskActive}
            userSettings={userSettings}
            activeCalendar={activeCalendar}
            />
        </> : 
        <>
        <EuiBadge>Owner: {activeTask.task.owner.replaceAll("amazonfederate_","")}</EuiBadge>
        </>
        }
    </EuiPanel>
    </div>
  );
}

export function TaskList({events, setEvents, projects, userData}) {
  const [selection,setSelection] = useState([]);
  const [timerIntTaskActive, setTimerIntTaskActive] = useGlobalState('timerIntTaskActive');
  const [timeCDSpent, setTimeCDSpent] = useGlobalState('timeCDSpent');
  const [isDeleting,setIsDeleting] = useState(false);

  const renderToolsLeft = () => {
    if (selection.length === 0) {
      return;
    }

    const deleteTasks = async () => {
      setIsDeleting(true);
      let tasksToRemove = [];
      for(let i in selection) {
        console.log(selection[i]);
        let id = selection[i].id;
        tasksToRemove.push(id);
        await API.graphql({ query: deleteTaskMutation, variables: { input: { id } }});
        if(id === timerIntTaskActive.id) {
          if(timerInt) { workerTimers.clearInterval(timerInt); }
          timerInt = null;
          setTimerIntTaskActive({});
          setTimeCDSpent(0);
        }
      }
      const newEventsArray = events.filter(event => !tasksToRemove.includes(event.task.id));
      setEvents(newEventsArray);
      setIsDeleting(false);
      setSelection([]);
    };

    return (
      <EuiButton color="danger" iconType="trash" onClick={deleteTasks} isLoading={isDeleting}>
        Delete {selection.length} Tasks
      </EuiButton>
    );
  };

  const selectionValue = {
    selectable: (event) => event.task.owner === userData.username,
    onSelectionChange: (newSelection) => {
      setSelection(newSelection);
    },
    initialSelected: []
  };

  return (
    <EuiPanel color="transparent">
      <EuiInMemoryTable
        items={events}
        selection={selectionValue}
        isSelectable={true}
        itemId="id"
        pagination={{
          pageSizeOptions: [25, 50, 0],
        }}
        search={{
          toolsLeft: renderToolsLeft(),
          filters: [
            {
              type: 'field_value_selection',
              field: 'task.projectID',
              name: 'Project(s)',
              multiSelect: false,
              options: projects.map((project) => ({
                value: project.value,
                name: project.text
              }))
            }
          ]
        }}
        sorting={{
          sort: {
            field: 'task.startDateTime',
            direction: 'desc'
          }
        }}
        columns={[
          {
            field: 'task.name',
            name: 'Name'
          },
          {
            field: 'task.startDateTime',
            name: 'Start',
            sortable: ( event ) => moment(event.task.startDateTime,moment.ISO_8601).unix(),
            render: (date) => (
              moment(date,moment.ISO_8601).format("YYYY-MM-DD hh:mm:ss A")
            )
          },
          {
            field: 'task.duration',
            name: 'Duration',
            sortable: ( event ) => (event.task.duration),
            render: (duration) => {
              let renderedDuration = duration/3600;
              if(renderedDuration<1) {
                return renderedDuration*60+" min(s)";
              } else {
                return renderedDuration+" hr(s)";
              }
            }
          },
          {
            field: 'task.projectID',
            name: 'Project',
            render: (project) => {
              let name = "Unknown Project";
              let findProject = projects.find((projectSearch)=> projectSearch.value === project);
              if(findProject) {
                name = findProject.text;
              }
              return name;
            }
          }
        ]}
      />
    </EuiPanel>
  );
}