import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { API } from 'aws-amplify';
import { 
    listProjectsByUserID, 
    listProjectsByTeamID,
    listGoalsByOwner, 
    getStatsFn, 
    listCalendarsByOwner, 
    listTaskByCalendarIDuserID,
    listShareCalendarsByRecipient,
    listShareCalendarsByOwner,
    listTaskByCalendarIDShareTeam,
    listTeamGroupByDataByTeamID,
    getOutlookDataRequest
  } from './graphql/queries';
  import { 
    updateUserFn as updateUserFnMutation,
    deleteOutlookDataRequest as deleteOutlookDataRequestMutation
  } from './graphql/mutations';  
import {
    EuiPageTemplate,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiSpacer,
    useEuiTour,
    EuiPanel,
    EuiAvatar,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiTitle,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiCallOut,
    useEuiTheme,
    logicals
  } from '@elastic/eui';
import { css } from '@emotion/react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    timeFormatArr, 
    defaultProjects, 
    applicationServerPublicKey, 
    initialTaskFormState,
    statusStrings,
    difficultyStrings,
    focusStrings
} from './Constants';
import TopMenu from './TopMenu';
import { Reports } from './Reports';
import { CalendarSelect } from './Calendar';
import { ProjectManagement } from './Project';
import { SettingsPage } from './Settings';
import { TeamPage } from './Team';
import { TaskList } from './Task';
import { LeaderBoard } from './LeaderBoard';
import { 
    CreateTask, 
    PopoutEvent, 
    StopTaskModal, 
    CreateTaskModal
} from './Task';
import OutlookImport from './OutlookImport';
import { useGlobalState } from './GlobalState';

const localizer = momentLocalizer(moment);

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function FirstVisitModal({userData,userSettings,tourActions,setUserSettings}) {
    const [showFirstVisit,setShowFirstVisit] = useState(false);
    const [step,setStep] = useState(1);
  
    useEffect(() => {
      if(userSettings.firstVisit === null || userSettings.firstVisit) {
        setShowFirstVisit(true);
      }
    },[userSettings]);
  
    return (
      <div>
        {
          showFirstVisit && <EuiModal
            onClose={() => {
              setShowFirstVisit(false);
              setUserSettings({ ...userSettings, 'firstVisit': false});
              API.graphql({ query: updateUserFnMutation, variables: { input: {'firstVisit':false}} }).catch((error)=>{});
            }}
            style={{ minWidth: 800 }}
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h1><EuiAvatar name="GettingStarted" type="space" iconType="plus" size="m" color="#0071c2" />&nbsp;Getting Started</h1>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
  
            <EuiModalBody>
              {step === 1 && <EuiText size="m">
                Welcome to TimeBlok, an application that gives Amazonians analytics on their time and focus. How, what and where we spend our time determines our job satisfaction and customer outcomes. TimeBlok makes this easy. So easy. Press next to get an introduction to common TimeBlok features and workflows.
              </EuiText>}
              {step === 2 && 
              <>
                <EuiFlexGroup>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Tasks</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        Tasks are a TimeBlok concept for time spent working. Tasks can be created to record work you will do in the future, the present or the past. TimeBlok makes creating a Task easy with a side menu on the main page, selecting a timeslot from the main calendar view, or importing your Outlook Calendar.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={3}>
                    <img alt="More Options" src="tutorial/tutorialtask.png" style={{"width": "250px", "display": "inline", "margin": 0}}/>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem grow={3}>
                  <img alt="More Options" src="tutorial/tutorialprojectsgoals.png" style={{"width": "250px", "display": "inline", "margin": 0}}/>
                  </EuiFlexItem>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Create Projects and Goals</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        One of TimeBlok's main features is the ability to analyze how you are spending your time and make informed decisions based on that data. In order to analyze your time, TimeBlok allows you to create shorter term Projects which you can use to categorize your Tasks. Projects can then be further categorized under longer term Goals. That way you can see how your Tasks are contributing to your Projects and Goals. You can manage your Projects and Goals in the Projects section in the main menu at the top of the Main page.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Import your Calendar</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        In order to quickly get started with using TimeBlok, you can import your Calendar, categorize the events by Project, and create Tasks based on those events. This makes it easy to continously update and analyze your time by utilizing data that you are already tracking in your Outlook Calendar. You can import your Calendar by clicking on the Outlook Import button under the Tools menu in the top left of the Main page.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialoutlook.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                </EuiFlexGroup>
              </>
              }
              { step === 3 &&
                <>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={7}>
                      <EuiTitle size="s">
                        <h1>Analyze your Time</h1>
                      </EuiTitle>
                      <EuiText size="m">
                        <> 
                          TimeBlok gives you the ability to analyze how you are spending your time. The reports page give you several metrics and analytics that can help you prioritize your time to ensure you are spending your time towards your important goals and projects. You can view your reports by clicking on the Reports button under the Tools menu in the top top left of the Main page.
                        </>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialreports.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialreports2.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                    <EuiFlexItem grow={7}>
                      <EuiTitle size="s">
                        <h1>TimeSpent graph</h1>
                      </EuiTitle>
                      <EuiText size="m">
                        <> 
                          The TimeSpent graph gives you a view into how you are spending your time each day. The graph also breaks down the time spent on each project when you hover over the bar graph for each day.
                        </>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={7}>
                      <EuiTitle size="s">
                        <h1>Goals Progress</h1>
                      </EuiTitle>
                      <EuiText size="m">
                        <> 
                          The Goals Progress graph gives you a view on how you are spending your time in regards to your longer term Goals. The Goals will be ordered by the priority you have set when creating your Goals. 
                        </>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialreports3.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialreports4.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                    <EuiFlexItem grow={7}>
                      <EuiTitle size="s">
                        <h1>Piecharts</h1>
                      </EuiTitle>
                      <EuiText size="m">
                        <> 
                          There are several Piecharts that show your time breakdown by Projects, Goals, and both. This is another view that helps you understand where you are spending your time.
                        </>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
              </>
              }
              {step === 4 && <>
                <EuiFlexGroup>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Multiple Calendars</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        TimeBlok supports having multiple Calendars. That way you can setup one Calendar for work and one Calendar for personal to analyze your events separately. You can also have multiple Calendars if you want to analyze someone else's Calendar that doesnt have time to use TimeBlok.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={3}><img alt="Calendars" src="tutorial/tutorialcalendars.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem grow={3}><img alt="Calendars" src="tutorial/tutorialcalendars2.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Calendar Sharing</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        You can share your Calendar with other TimeBlok users. This is useful if you want to have another user analyze your Calendar and provide advice about how you are spending your time. One use-case is to share your Calendar with your manager or coach so that they can provide advice on how to better achieve your career goals.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
              }
              {step === 5 && <>
                <EuiFlexGroup>
                  <EuiFlexItem grow={7}>
                    <EuiTitle size="s">
                      <h1>Notifications</h1>
                    </EuiTitle>
                    <EuiText size="m">
                      <> 
                        TimeBlok can you be used in different ways. If you plan to use TimeBlok to manage your time, you can setup notifications to alert you when it is time to start a Task. The notifications can be set to send you an email, alert you from the browser, or send you an SMS. You can also set the frequency of the notification. You can view your Notification settings in the Settings page.
                      </>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialsettings.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                    <EuiFlexItem grow={3}><img alt="More Options" src="tutorial/tutorialsettings2.png" style={{"width": "250px", "display": "inline", "margin": 0}}/></EuiFlexItem>
                    <EuiFlexItem grow={7}>
                      <EuiTitle size="s">
                        <h1>Other Settings</h1>
                      </EuiTitle>
                      <EuiText size="m">
                        <> 
                          There are other settings you can set in the Settings page. One of them is creating Outlook invites from Tasks you create in TimeBlok. You can turn this feature on with the toggle Task Email Invites. Other settings you can change are Time Format and Start and End Times for the Calendar view.
                        </>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
              </>}
              {step === 6 && <>
                <EuiTitle size="s">
                  <h1>More Resources</h1>
                </EuiTitle>
                <EuiText size="m">
                The following are several resources where you can find more information about TimeBlok. 
                <ul>
                  <li><a href="https://w.amazon.com/bin/view/TimeBlok/">Wiki</a></li>
                  <li><a href="https://broadcast.amazon.com/channels/45343">Broadcast Channel</a></li>
                  <li><a href="https://amzn-aws.slack.com/archives/C03MR7VJAPL">Slack Channel</a></li>
                  <li><a href="https://blog.timeblok.app/">Blog</a></li>
                </ul>
                </EuiText>
                <EuiTitle size="s">
                  <h1>Ready to use TimeBlok?</h1>
                </EuiTitle>
                <EuiText size="m">
                  You can start using TimeBlok now or start our step-by-step Tutorial which will walk you through a common workflow to import your Outlook Calendar, create Projects/Goals, and analyze your Time. Click on Next to start the Tutorial. Otherwise click on Skip Tutorial to start using TimeBlok.
                </EuiText>
              </>}
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => {
                setShowFirstVisit(false);
                setUserSettings({ ...userSettings, 'firstVisit': false});
                API.graphql({ query: updateUserFnMutation, variables: { input: {'firstVisit':false}} }).catch((error)=>{});
              }}>Skip Tutorial</EuiButtonEmpty>
  
              <EuiButton fill
                onClick={() => {
                  if(step < 6) {
                    let currentStep = step;
                    setStep(currentStep+1);
                  } else if(step === 6) {
                    tourActions.resetTour();
                    setShowFirstVisit(false);
                    setUserSettings({ ...userSettings, 'firstVisit': false});
                    API.graphql({ query: updateUserFnMutation, variables: { input: {'firstVisit':false}} }).catch((error)=>{});
                  } else {
                    setShowFirstVisit(false);
                  }
                }}
              >
                Next
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        }
      </div>
    );
}

export function MainView({userData,setUserData,logOut}) {
    const [userSettings, setUserSettings] = useState({id: '', emailnotify: false, ftmin: false, fvmin: false, calendarInvite: false, importAutoCat: false, phonenotify: false,calstarttime: 6,calendtime: 18,webnotify: true, timeformat: 0, outlookProjectSelection:[]});
    const [events, setEvents] = useState([]);
    const [openCreateTask, setOpenCreateTask] = useState(null);
    const [projects, setProjects] = useState([]);
    const [goals, setGoals] = useState([]);
    const [calendars, setCalendars] = useState([]);
    const [activeCalendar, setActiveCalendar] = useState({});
    const [teamProjects, setTeamProjects] = useState([]);
    const [activeMenu, setActiveMenu ] = useState("home");
    const [notificationPermission,setNotificationPermission] = useState(true);
    const [openStopTask, setOpenStopTask] = useGlobalState('openStopTask');
    const [activeTask, setActiveTask] = useState(false);
    const [teamEvents, setTeamEvents] = useState([]);
    const [displayStartTime,setDisplayStartTime] = useState([]);
    const [stats,setStats] = useState({"Users": 0});
    const [reportProjects,setReportProjects] = useState(projects);
    const [reportEvents,setReportEvents] = useState([]);
    const [reportCalendars,setReportCalendars] = useState(calendars);
    const [reportStart, setReportStart] = useState('now/w');
    const [reportEnd, setReportEnd] = useState('now');
    const [sharedToMeCalendars, setSharedToMeCalendars] = useState([]);
    const [sharedByMeCalendars, setSharedByMeCalendars] = useState([]);
    const [teamCalendars,setTeamCalendars] = useState([]);
    const [teamGroupByData,setTeamGroupByData] = useState({});
    const [appContext, setAppContext] = useGlobalState('appContext');
    const [selectedTab,setSelectedTab] = useState("Calendar");
    const [outlookDataResponse, setOutlookDataResponse] = useState([]);
    const [message,setMessage] = useState("");
    const [formats, setFormats] = useState(
      {
        timeGutterFormat: timeFormatArr[0],
        selectRangeFormat: ({ start, end }, culture, local) => `${local.format(start, timeFormatArr[0], culture)} — ${local.format(end, timeFormatArr[0], culture)}`,
        eventTimeRangeFormat: ({ start, end }, culture, local) => `${local.format(start, timeFormatArr[0], culture)} — ${local.format(end, timeFormatArr[0], culture)}`
      }
    );
    const { euiTheme } = useEuiTheme();
  
    const demoTourSteps = [
      {
        step: 1,
        title: 'Import your Outlook Calendar',
        content: <p style={{maxWidth: 350}}>Start by importing your Calendar, click here and follow the instructions to import your Calendar.</p>
      },
      {
        step: 2,
        title: 'Categorize your Calendar events',
        content: <p style={{maxWidth: 350}}>Select a Project for each imported calendar event. If that Project doesn't exist, you can create one by clicking on the button next to the Project selection box. You need to select/create a Project for each event you want to import.</p>,
        anchorPosition: "upLeft"
      },
      {
        step: 3,
        title: 'Import your Calendar',
        content: <p style={{maxWidth: 350}}>Blank</p>
      },
      {
        step: 4,
        title: 'Analyze your Calendar',
        content: <p style={{maxWidth: 350}}>Analyze your Calendar by opening the Reports page.</p>
      },
      {
        step: 5,
        title: 'Set the Date Range to Analyze',
        content: <p style={{maxWidth: 350}}>Set the date range to dates specified in your Outlook import.</p>
      },
      {
        step: 6,
        title: 'Create Goals',
        content: <p style={{maxWidth: 350}}>Analytics and reporting are partly based on categorizing your Projects based on Goals.  Click here to create your own Goals and assign Projects to those Goals.</p>
      },
      {
        step: 7,
        title: 'Create Goals',
        content: <p style={{maxWidth: 350}}>Create an Goal. Think of an Goal as something you want to accomplish longer term and assign Projects under the Goal that will help achieve that Goal.</p>
      },
      {
        step: 8,
        title: 'Assign Project(s) to Goal',
        content: <p style={{maxWidth: 350}}>Assign Projects to your Goal. You can do that by dragging the Projects onto the Goal panels. Goals can be re-ordered by level of importance.</p>,
        anchorPosition: "downRight"
      },
      {
        step: 9,
        title: 'Analyze your Calendar with Goals',
        content: <p style={{maxWidth: 350}}>Go back to the Reports page and Re-Analyze your Calendar.</p>,
        anchorPosition: "downRight"
      },
      {
        step: 10,
        title: 'Analyze your Calendar with Goals',
        content: <p style={{maxWidth: 350}}>Goals will be ordered by their importance. You can see how much time you are spending towards your Goals. There are additional pie charts below that also break down how you are spending your time.</p>,
        anchorPosition: "upRight"
      },
    ];
    
    const tourConfig = {
      currentTourStep: 1,
      isTourActive: false,
      tourSubtitle: 'Getting Started',
    };
  
    const [
      euiTourSteps,
      tourActions,
      tourReducerState,
    ] = useEuiTour(demoTourSteps, tourConfig);
  
    const defaultProjectsMap = useMemo(
      () => {
        let _defaultProjectsMap = [];
        for(const [key, value] of Object.entries(defaultProjects)) {
          _defaultProjectsMap.push({"key":key,"value":key,"text":value, "label":value,"checked":'on'});
        }
        return _defaultProjectsMap;
      },
      [],
    );
  
    const setTimeFormat = useCallback(async () => {
      if(userSettings.timeformat) {
        setFormats({
          timeGutterFormat: timeFormatArr[userSettings.timeformat],
          selectRangeFormat: ({ start, end }, culture, local) => `${local.format(start, timeFormatArr[userSettings.timeformat], culture)} — ${local.format(end, timeFormatArr[userSettings.timeformat], culture)}`,
          eventTimeRangeFormat: ({ start, end }, culture, local) => `${local.format(start, timeFormatArr[userSettings.timeformat], culture)} — ${local.format(end, timeFormatArr[userSettings.timeformat], culture)}`
        });
        let _displayStartTime=[];
        for(let i = 0; i <= 24; i++) {
          let time = moment(i+":00:00", 'HH:mm:ss').format(timeFormatArr[userSettings.timeformat]);
          _displayStartTime.push({
            'key':i,
            'value':i,
            'text':time
          });
        }
        setDisplayStartTime(_displayStartTime);
      }
    }, [userSettings.timeformat]);
  
    const fetchCalendars = useCallback(async () => {
      setMessage("");
      if(userSettings.id) {
        let _fetchedCalendars = [];
        try {
          let apiData = await API.graphql({ query: listCalendarsByOwner, variables: { 
              owner: userData.username
            }
          });
          _fetchedCalendars = apiData.data.listCalendarsByOwner.items.filter((obj)=>!obj.teamID).map(calendar => ({
            "value" : calendar.id,
            "key": calendar.id,
            "text" : calendar.name,
            "label" : calendar.name,
            "description": calendar.description,
            "createtask": true,
            "owner": calendar.owner
          }));
        } catch(e) {
          setMessage("There was an error getting your calendar(s). Please contact the administrator if this issue persists.");
        }

        try {
          let apiData = await API.graphql({ query: listShareCalendarsByRecipient, variables: { recipient: userData.username }});
          let _fetchedSharedToMeCalendars = apiData.data.listShareCalendarsByRecipient.items.filter((obj)=>(!obj.teamID && obj.calendar)).map(shareCalendar => ({
              "value" : shareCalendar.calendar.id,
              "key": shareCalendar.calendar.id,
              "sharecalendarid": shareCalendar.id,
              "sender": shareCalendar.sender,
              "text" : shareCalendar.sender.replace("amazonfederate_","")+"'s "+shareCalendar.calendar.name,
              "label" : shareCalendar.sender.replace("amazonfederate_","")+"'s "+shareCalendar.calendar.name,
              "description": shareCalendar.description,
              "createtask": false,
              "owner": shareCalendar.sender,
              data: {
                secondaryContent: shareCalendar.description,
              }
          }));
          setSharedToMeCalendars(_fetchedSharedToMeCalendars);
          let _fetchedTeamCalendars = apiData.data.listShareCalendarsByRecipient.items.filter((obj)=>(obj.teamID && obj.calendar)).map(shareCalendar => ({
              "value" : shareCalendar.calendar.id,
              "key": shareCalendar.calendar.id,
              "sharecalendarid": shareCalendar.id,
              "teamid": shareCalendar.teamID,
              "checked": "on",
              "text" : shareCalendar.calendar.name,
              "label" : shareCalendar.calendar.name,
              "description": shareCalendar.description,
              "createtask": true,
              "owner": shareCalendar.owner,
              data: {
                secondaryContent: shareCalendar.description,
              }
          }));
          setTeamCalendars(_fetchedTeamCalendars);
          for(let i in _fetchedTeamCalendars) {
            delete _fetchedTeamCalendars[i].checked;
          }
          setReportCalendars([..._fetchedCalendars,..._fetchedSharedToMeCalendars,..._fetchedTeamCalendars]);
          let _calendars = [..._fetchedCalendars,..._fetchedTeamCalendars,..._fetchedSharedToMeCalendars];
          setCalendars([..._calendars]);
          let primaryCalendar;
          let selectedCalendar;
          let teamCalendar;
          for(let i in _calendars) {
            if(_calendars[i].key === userSettings.selectedCalendarID) {
              selectedCalendar = _calendars[i];
            } else if(_calendars[i].teamid) {
              teamCalendar = _calendars[i];
            } else if(_calendars[i].text === "Primary") {
              primaryCalendar = _calendars[i];
            }
          }
          if(selectedCalendar) {
            setActiveCalendar(selectedCalendar);
          } else if(teamCalendar) {
            setActiveCalendar(teamCalendar);
          } else {
            setActiveCalendar(primaryCalendar);
          }
        } catch(e) {
          setMessage("There was an error getting a calendar shared to you. Please contact the administrator if this issue persists.");
        }
        
        try {
          let apiData = await API.graphql({ query: listShareCalendarsByOwner, variables: { owner: userData.username }});
          let _fetchedSharedByMeCalendars = apiData.data.listShareCalendarsByOwner.items.filter((obj)=>(!obj.teamID && obj.calendar)).map(shareCalendar => ({
              "value" : shareCalendar.calendar.id,
              "key": shareCalendar.calendar.id,
              "sharecalendarid": shareCalendar.id,
              "recipient": shareCalendar.recipient,
              "calendarid": shareCalendar.calendarID,
              "text" : shareCalendar.calendar.name + " shared to "+shareCalendar.recipient.replace("amazonfederate_",""),
              "label" : shareCalendar.calendar.name + " shared to "+shareCalendar.recipient.replace("amazonfederate_",""),
              "description": shareCalendar.description,
              "owner": shareCalendar.owner,
              data: {
                secondaryContent: shareCalendar.description,
              }
          }));
          setSharedByMeCalendars(_fetchedSharedByMeCalendars);
        } catch(e) {
          setMessage("There is an error sharing a calendar. Please contact the administrator if this issue persists.");
        }
      }
    }, [userData.username,userSettings.id]);
  
    const fetchGoals = useCallback(async () => {
      const apiData = await API.graphql({ query: listGoalsByOwner, variables: { 
          owner: userData.username
        }
      });
      let _fetchedGoals = apiData.data.listGoalsByOwner.items.map(goal => ({
          "value" : goal.id,
          "key": goal.id,
          "text" : goal.name,
          "label" : goal.name,
          "notes": goal.notes,
          "order": goal.order,
          "calendarids": goal.calendarID
      }));
      _fetchedGoals = _fetchedGoals.sort((a, b) => (a.order > b.order) ? 1 : -1)
      setGoals(_fetchedGoals);
    }, [userData.username]);
  
    const fetchProjects = useCallback(async () => {
      if(userData.username && activeCalendar.value && userSettings.id) {
        let projectsToSet = [];
        let addDefaultProjects = false;
        let _fetchedProjects2 = [];
  
        if(userSettings.teamID) {
          const apiData2 = await API.graphql({ query: listProjectsByTeamID, variables: { 
              teamID: userSettings.teamID
            }
          });
          _fetchedProjects2 = apiData2.data.listProjectsByTeamID.items.filter((obj)=>obj.calendarID.includes(JSON.parse(userSettings.team).calendarID)).map(project => ({
            "value" : project.id,
            "key": project.id,
            "text" : project.name,
            "label" : project.name,
            "altnames": project.altNames,
            "checked" : 'on',
            "tags": project.tags,
            "notes": project.notes,
            "color": project.color,
            "goalid": project.goalID,
            "goal": project.goal,
            "team": "true",
            "calenderids": project.calendarID
          }));
  
          setTeamProjects(_fetchedProjects2);
        }
  
        if(activeCalendar.teamid) {
          projectsToSet= [..._fetchedProjects2];
        } else {
          const apiData = await API.graphql({ query: listProjectsByUserID, variables: { 
              userID: userData.username
            }
          });
          let _fetchedProjects = apiData.data.listProjectsByUserID.items.filter(project => !project.teamID).map(project => ({
            "value" : project.id,
            "key": project.id,
            "text" : project.name,
            "label" : project.name,
            "altnames": project.altNames,
            "checked" : 'on',
            "tags": project.tags,
            "notes": project.notes,
            "color": project.color,
            "goalid": project.goalID,
            "goal": project.goal,
            "team": "false",
            "calenderids": project.calendarID
          }));
          projectsToSet= [..._fetchedProjects];
          addDefaultProjects = true;
        }
        
        if(addDefaultProjects) {
          for(let i in defaultProjectsMap) {
            let foundProject = false;
            for(let y in projectsToSet) {
              if(defaultProjectsMap[i].text === projectsToSet[y].text) {
                foundProject = true;
              }
            }
            if(!foundProject) {
              projectsToSet.push(defaultProjectsMap[i]);
            }
          }
        }
        projectsToSet = projectsToSet.sort((a, b) => {
          return a.text.localeCompare(b.text);
        });
        setProjects(projectsToSet);
      }
    }, [userData.username,defaultProjectsMap,setProjects,activeCalendar,userSettings.id,userSettings.teamID]);
    
    async function getNotificationPermission() {
      window.Notification.requestPermission().then(async function (permission) {
        if(permission === "denied") {
          setNotificationPermission(false);
        } else if(permission === "granted") {
          setNotificationPermission(true);
        }
      });
    }
    
    async function subscribeUser(sub,swRegistration) {
      const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
      swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
      .then(function(subscription) {
        console.log('User is subscribed.');
        API.graphql({ query: updateUserFnMutation, variables: { input: {'fcmToken':JSON.stringify(subscription)}} }).catch((error)=>{});
      })
      .catch(function(err) {
        console.log('Failed to subscribe the user: ', err);
      });
    }
  
    /*
    const fetchRecurringTasks = useCallback(async (date, tasks) => {
      let startRange = date.clone();
      let endRange = date.clone();
  
      startRange.startOf('week');
      endRange.endOf('week');
  
      console.log("Fetching recurring tasks");
      const apiData = await API.graphql({ query: listRecurringTaskByUserID, variables: {
        userID: userData.username,
        limit: 1000
      }});
  
      let newEvents = [];
      for(let i in apiData.data.listRecurringTaskByUserID.items) {
        let recurringTask = apiData.data.listRecurringTaskByUserID.items[i].recurringTaskType;
        if(!recurringTask) {
          recurringTask = 2;
        }
        let nextCreationDay = 0;
        let creationCount = 1;
        if(recurringTask === 1) {
          nextCreationDay = 7;
          creationCount = 5;
        } else if(recurringTask === 2) {
          nextCreationDay = 7;
        } else if(recurringTask === 3) {
          nextCreationDay = 14;
        } else if(recurringTask === 4) {
          nextCreationDay = 30;
        }
        let nextCreationTime = moment(apiData.data.listRecurringTaskByUserID.items[i].nextCreationTime,moment.ISO_8601);
        if(nextCreationTime.diff(startRange) >= 0 && nextCreationTime.diff(endRange) <= 0) {
          let targetDate = date.clone();
          targetDate.day(moment(apiData.data.listRecurringTaskByUserID.items[i].startDateTime, moment.ISO_8601).day());
          targetDate.hour(moment(apiData.data.listRecurringTaskByUserID.items[i].startDateTime, moment.ISO_8601).hour());
          targetDate.minutes(moment(apiData.data.listRecurringTaskByUserID.items[i].startDateTime, moment.ISO_8601).minutes());
          targetDate.seconds(moment(apiData.data.listRecurringTaskByUserID.items[i].startDateTime, moment.ISO_8601).seconds());
          let newTask = initialTaskFormState;
          newTask.startDateTime = targetDate.toISOString();
          while(creationCount > 0) {
            newTask.name = apiData.data.listRecurringTaskByUserID.items[i].name;
            newTask.duration = apiData.data.listRecurringTaskByUserID.items[i].duration;
            newTask.reccuringTaskID = apiData.data.listRecurringTaskByUserID.items[i].id;
            newTask.status = 0;
            newTask.userID = apiData.data.listRecurringTaskByUserID.items[i].userID;
            newTask.projectID = apiData.data.listRecurringTaskByUserID.items[i].projectID;
            console.log("Creating Task for Recurrence");
            newTask.calendarID = activeCalendar.value;
            newTask.calendarIDgroup = activeCalendar.value;
            newTask.calendarIDuserID = activeCalendar.value+""+apiData.data.listRecurringTaskByUserID.items[i].userID;
            delete newTask.projectName;
            const newTaskApiData =  await API.graphql({ query: createTaskFnMutation, variables: { input: newTask } });
            newTaskApiData.data.createTask = newTaskApiData.data.createTaskFn;
            let _event = {
              "title" : newTaskApiData.data.createTask.name,
              "task" : newTaskApiData.data.createTask,
              "start": moment(newTaskApiData.data.createTask.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate(),
              "end": moment(newTaskApiData.data.createTask.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(newTaskApiData.data.createTask.duration, 'seconds').toDate()
            }
            newEvents.push(_event);
            if(recurringTask === 1) {
              let date = moment(newTask.startDateTime, moment.ISO_8601);
              date.add(1,'d');
              if(date.day() === 6) {
                date.add(2,'d');
              }
              newTask.startDateTime = date.toISOString();
            }
            creationCount--;
          }
  
          API.graphql({ query: updateRecurringTaskMutation, variables: { input: {id: apiData.data.listRecurringTaskByUserID.items[i].id, nextCreationTime: targetDate.add(nextCreationDay, 'd').toISOString()}}});
        }
      }
      setEvents([ ...tasks, ...newEvents ]);
    },[userData, activeCalendar.value]);
    */
    
    const fetchTasks = useCallback(async (date) => {
      if(activeCalendar.value) {
        console.log("Checking for passed in Task");
        const queryParams = new URLSearchParams(window.location.search);
        const taskPassed = queryParams.get('task');
        const endNow = queryParams.get('endNow');
        
        console.log("Fetching tasks");
        if(!date) {
          date = moment();
        }
  
        let calendarIDuserID = activeCalendar.value+""+activeCalendar.owner;
        if(activeCalendar.teamid) {
          calendarIDuserID = activeCalendar.value+""+userData.username;
        }
  
        let startRange = date.clone();
        let endRange = startRange.clone();
  
        let tasksArr = [];
        let nextToken = 1;
        while(nextToken) {
          let variableObj = { 
            calendarIDuserID: calendarIDuserID,
            startDateTime: {between: [startRange.subtract(60, 'days').toISOString(), endRange.endOf('month').add(7, 'days').toISOString()]},
            limit: 1000
          };
          if(nextToken !== 1) {
            variableObj.nextToken = nextToken;
          }
          const apiData = await API.graphql({ query: listTaskByCalendarIDuserID, variables: variableObj});
          let _fetchedTasks = apiData.data.listTaskByCalendarIDuserID.items.map(task => ({
            "title" : task.name,
            "task" : task,
            "id" : task.id,
            "start": moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate(),
            "end": (task.status === 2 ? moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.timeSpent, 'seconds').toDate() : moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.duration, 'seconds').toDate())
          }));
          nextToken = apiData.data.listTaskByCalendarIDuserID.nextToken;
          tasksArr = [...tasksArr,..._fetchedTasks];
        }
  
        let teamTaskArr = [];
        if(activeCalendar.teamid) {
          nextToken = 1;
          while(nextToken) {
            let variableObj = { 
              calendarIDshareTeam: activeCalendar.value + "" + 2,
              startDateTime: {between: [startRange.subtract(60, 'days').toISOString(), endRange.endOf('month').add(7, 'days').toISOString()]},
              limit: 1000
            };
            if(nextToken !== 1) {
              variableObj.nextToken = nextToken;
            }
            const apiData = await API.graphql({ query: listTaskByCalendarIDShareTeam, variables: variableObj});
            let _fetchedTasks = apiData.data.listTaskByCalendarIDShareTeam.items.map(task => ({
              "title" : task.name,
              "task" : task,
              "id" : task.id,
              "start": moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").toDate(),
              "end": (task.status === 2 ? moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.timeSpent, 'seconds').toDate() : moment(task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(task.duration, 'seconds').toDate())
            }));
            nextToken = apiData.data.listTaskByCalendarIDShareTeam.nextToken;
            teamTaskArr = [...teamTaskArr,..._fetchedTasks];
          }
        }
        teamTaskArr = teamTaskArr.filter(task => task.task.owner !== userData.username);
        tasksArr = [...tasksArr,...teamTaskArr];
  
        setEvents(tasksArr);
        
        tasksArr.forEach(async function(event) {
          if(taskPassed) {
            if(event.task.id === taskPassed) {
              event.endNow = endNow;
              document.querySelector("#EventDiv-"+event.task.id).children[0].click();
            }
          } else {
            let now = new Date();
            if(now >= event.start && now <= event.end) {
              if(document.querySelector("#EventDiv-"+event.task.id)) {
                document.querySelector("#EventDiv-"+event.task.id).children[0].click();
              }
            }
          }
        });
  
        if(moment(date, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)").diff(moment().startOf('week')) >= 0) {
          //fetchRecurringTasks(moment(date, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)"), tasksArr);
        }
      }
    }, [activeCalendar.value, userData.username]);
  
    const fetchUserSettings = useCallback(async (date) => {
      console.log("Getting User Settings");
      let _userSettings = await API.graphql({ query: `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            id
            name
            emailnotify
            ftmin
            fvmin
            phonenotify
            calstarttime
            calendtime
            phone
            email
            webnotify
            calendarInvite
            importAutoCat
            timeformat
            teamID
            team {
              id
              password
              teamMembers
              teamAdmins
              owner
              calendarID
            }
            outlookProjectSelection
            firstVisit
            reportsDateRange
            selectedCalendarID
          }
        }
      `, variables: { id: userData.username }});
      if(_userSettings.data.getUser.team) {
        let teamID = _userSettings.data.getUser.team.id;
        let nextToken = 1;
        let itemsArr = {};
        while(nextToken) {
          let variableObj = {
            teamID: teamID,
            limit: 1000
          };
          if(nextToken !== 1) {
            variableObj.nextToken = nextToken;
          }
          let query = { query: listTeamGroupByDataByTeamID, variables: variableObj};
          const apiData = await API.graphql(query);
          let returnObj = apiData.data.listTeamGroupByDataByTeamID;
          for(let i in returnObj.items) {
            itemsArr[returnObj.items[i].userID] = returnObj.items[i];
          }
          nextToken = returnObj.nextToken;
        }
        setTeamGroupByData(itemsArr);
      }
  
      _userSettings.data.getUser.team = JSON.stringify(_userSettings.data.getUser.team);
  
      if(_userSettings.data.getUser.reportsDateRange) {
        setReportStart(_userSettings.data.getUser.reportsDateRange.split("^")[0]);
        setReportEnd(_userSettings.data.getUser.reportsDateRange.split("^")[1]);
      }
      if(_userSettings.data.getUser.importAutoCat === null) {
        _userSettings.data.getUser.importAutoCat = false;
      }
      setUserSettings(_userSettings.data.getUser);
  
      getNotificationPermission();
      
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
        navigator.serviceWorker.register('sw.js')
        .then(async function(swReg) {
          await navigator.serviceWorker.ready;
          console.log('Service Worker is registered', swReg);
          subscribeUser(userData.username,swReg);
        })
        .catch(function(error) {
          console.error('Service Worker Error', error);
        });
      } else {
        console.warn('Push messaging is not supported');
      }
    }, [userData]);
  
    const fetchStats = useCallback(async () => {
      console.log("Getting Stats");
      const apiData = await API.graphql({ query: getStatsFn, variables: { 
          input: ""
        }
      });
      let statsObject = JSON.parse(apiData.data.getStatsFn);
      let statsCreateObj = {};
      for(let i in statsObject) {
        if(statsObject[i].name.S === "Users") {
          statsCreateObj.Users = Number(statsObject[i].value.N);
        }
      }
      setStats(statsCreateObj);
    }, []);

    const sleep = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    const fetchOutlookDataRequest = useCallback(async () => {
      if(userData.username && userSettings.id && projects.length) {
        console.log("Getting Outlook Data Requests");
        let exit = 1;
        let retries = 0;
        do {
          const apiData = await API.graphql({ query: getOutlookDataRequest, variables: { 
              id: userData.username
            }
          });
          if(apiData.data.getOutlookDataRequest) {
            exit = 0;
            if(apiData.data.getOutlookDataRequest.status === 1) {
              exit = 1;
              let response = JSON.parse(apiData.data.getOutlookDataRequest.response);
              if(response) {
                await API.graphql({ query: deleteOutlookDataRequestMutation, variables: { input: {id: apiData.data.getOutlookDataRequest.id }} });
                setOutlookDataResponse(response);
              }
            }
          }
          retries++;
          if(retries > 30) {
            exit = 1;
          }
          await sleep(1000);
        } while(!exit);
      }
    }, [userData.username, projects, userSettings])
  
    useEffect(() => {
      if(calendars.length) {
        let filterCalendars = calendars.filter((obj) => obj.key !== activeCalendar.key);
        for(let i in filterCalendars) {
          if(!filterCalendars[i].teamid) {
            filterCalendars[i].checked="";
          }
        }
        activeCalendar.checked = "on";
        setCalendars([...filterCalendars,activeCalendar]);
      }
    }, [activeCalendar]);
  
    useEffect(()=>{
      setReportProjects(projects);
    },[projects]);
  
    useEffect(() => {
      fetchCalendars();
    }, [fetchCalendars]);
  
    useEffect(()=> {
      fetchUserSettings();
    }, [fetchUserSettings]);
  
    useEffect(()=> {
      fetchTasks();
    }, [fetchTasks]);
  
    useEffect(() => {
      fetchGoals();
    }, [fetchGoals]);
  
    useEffect(() => {
      fetchProjects();
    }, [fetchProjects]);
  
    useEffect(()=> {
      setTimeFormat();
    },[setTimeFormat]);
  
    useEffect(() => {
      fetchStats();
    }, [fetchStats]);

    useEffect(() => {
      fetchOutlookDataRequest();
    }, [fetchOutlookDataRequest]);

    return (
      <div className="MainView">
        <TopMenu userData={userData} activeMenu={activeMenu} setActiveMenu={setActiveMenu} events={events} setEvents={setEvents} projects={projects} setProjects={setProjects} userSettings={userSettings} setUserSettings={setUserSettings} stats={stats} euiTourSteps={euiTourSteps} tourActions={tourActions} tourReducerState={tourReducerState} activeCalendar={activeCalendar} setActiveCalendar={setActiveCalendar} calendars={calendars} sharedToMeCalendars={sharedToMeCalendars} setSharedToMeCalendars={setSharedToMeCalendars} sharedByMeCalendars={sharedByMeCalendars} setSharedByMeCalendars={setSharedByMeCalendars} logOut={logOut}/>
          {activeMenu === 'teamReports' && <Reports reportStart={reportStart} setReportStart={setReportStart} reportEnd={reportEnd} setReportEnd={setReportEnd} reportEvents={teamEvents} setReportEvents={setTeamEvents} reportProjects={reportProjects} setReportProjects={setReportProjects} reportType="Team" euiTourSteps={euiTourSteps} tourActions={tourActions} userSettings={userSettings} setUserSettings={setUserSettings} userData={userData} reportCalendars={teamCalendars} setReportCalendars={setTeamCalendars} defaultProjectsMap={defaultProjectsMap} teamGroupByData={teamGroupByData} teamProjects={teamProjects}/>
          }
          {activeMenu === 'team' && <TeamPage userData={userData} userSettings={userSettings} setUserSettings={setUserSettings} teamProjects={teamProjects} teamEvents={teamEvents} setTeamProjects={setTeamProjects} setActiveMenu={setActiveMenu} setTeamEvents={setTeamEvents} activeCalendar={activeCalendar} calendars={calendars} setCalendars={setCalendars} setTeamCalendars={setTeamCalendars} teamGroupByData={teamGroupByData} setTeamGroupByData={setTeamGroupByData} logOut={logOut}/>}
          {activeMenu === 'reports' && <Reports reportStart={reportStart} setReportStart={setReportStart} reportEnd={reportEnd} setReportEnd={setReportEnd} reportEvents={reportEvents} setReportEvents={setReportEvents} reportProjects={reportProjects} setReportProjects={setReportProjects} reportType="Primary" euiTourSteps={euiTourSteps} tourActions={tourActions} userSettings={userSettings} setUserSettings={setUserSettings} userData={userData} reportCalendars={reportCalendars} setReportCalendars={setReportCalendars} defaultProjectsMap={defaultProjectsMap} teamGroupByData={teamGroupByData}/>
          }
          {activeMenu === 'projects' && <ProjectManagement userData={userData} goals={goals} setGoals={setGoals} projects={projects} setProjects={setProjects} events={events} setEvents={setEvents} defaultProjectsMap={defaultProjectsMap} euiTourSteps={euiTourSteps} tourActions={tourActions} tourReducerState={tourReducerState} activeCalendar={activeCalendar}/>
          }
          {activeMenu === 'leaderboard' && <LeaderBoard/>}
          {activeMenu === 'settings' && <SettingsPage userData={userData} userSettings={userSettings} setUserSettings={setUserSettings} notificationPermission={notificationPermission} setNotificationPermission={setNotificationPermission} displayStartTime={displayStartTime} setActiveMenu={setActiveMenu}/>}
          {activeMenu === 'home' && <EuiPageTemplate 
            template="empty"
            restrictWidth="100%">
            {message && <EuiCallOut title="Message" color="primary" iconType="help">
              <p>
                {message}
              </p>
            </EuiCallOut> }
            <EuiFlexGroup>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    padding-left: calc(${euiTheme.size.s});
                    ${logicals['max-width']}: 370px;
                `}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiPanel paddingSize="m" grow={false}>
                      <EuiText grow={false} textAlign='center'>
                        <h3><EuiAvatar name="Quicklinks" type="space" iconType="calendar" size="m" color="#0071c2" />&nbsp;Calendar</h3>
                      </EuiText>
                      <EuiSpacer size='s' />
                      <CalendarSelect userData={userData} activeCalendar={activeCalendar} setActiveCalendar={setActiveCalendar} calendars={calendars} setCalendars={setCalendars} reportCalendars={reportCalendars} setReportCalendars={setReportCalendars} display="rowCompressed"/>
                    </EuiPanel>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>
                <CreateTask 
                  userData={userData} 
                  events={events} 
                  setEvents={setEvents} 
                  projects={projects}
                  setProjects={setProjects}
                  userSettings={userSettings}
                  setUserSettings={setUserSettings}
                  setActiveMenu={setActiveMenu}
                  euiTourSteps={euiTourSteps}
                  tourActions={tourActions}
                  tourReducerState={tourReducerState}
                  activeCalendar={activeCalendar}
                  calendars={calendars}
                  sharedToMeCalendars={sharedToMeCalendars}
                  setSharedToMeCalendars={setSharedToMeCalendars}
                  sharedByMeCalendars={sharedByMeCalendars}
                  setSharedByMeCalendars={setSharedByMeCalendars}
                  outlookDataResponse={outlookDataResponse}
                  setOutlookDataResponse={setOutlookDataResponse}
                />
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 1000px;
                `}>
                <EuiPanel>
                  <EuiTabs>
                    <EuiTab
                      key={"Calendar"}
                      onClick={() => setSelectedTab("Calendar")}
                      isSelected={"Calendar" === selectedTab}
                    >
                      Calendar View
                    </EuiTab>
                    <EuiTab
                      key={"List"}
                      onClick={() => setSelectedTab("List")}
                      isSelected={"List" === selectedTab}
                    >
                      List View
                    </EuiTab>
                  </EuiTabs>
                  <EuiSpacer/>
                    {selectedTab === "Calendar" && <EuiPanel style={{height: 1000}} color="transparent">
                      <Calendar
                        localizer={localizer}
                        events={events}
                        formats={formats}
                        defaultView={'work_week'}
                        views={['month', 'work_week', 'day']}
                        min={new Date(new Date().setHours((userSettings.calstarttime ? userSettings.calstarttime : 6), 0))}
                        max={new Date(new Date().setHours((userSettings.calendtime ? (parseInt(userSettings.calendtime)===24 ? 23 : userSettings.calendtime) : 18), (parseInt(userSettings.calendtime)===24 ? 59 : 0)))}
                        selectable={true}
                        tooltipAccessor={(obj)=>{
                          let returnStr="";
                          returnStr += "\nName: "+obj.task.name+"\n";
                          returnStr += "Status: "+statusStrings[obj.task.status]+"\n";
                          if(obj.task.status === 2) {
                            returnStr += "TimeSpent: "+(obj.task.timeSpent/3600).toFixed(1)+" hours\n";
                            returnStr += "Actual End: "+obj.end+"\n";
                          } else {
                            returnStr += "Sched. Duration: "+(obj.task.duration/3600).toFixed(1)+" hours\n";
                            returnStr += "Sched. End: "+obj.end+"\n";
                          }
                          if(obj.task.description) { returnStr += "Notes:" +obj.task.description+"\n"; }
                          if(obj.task.difficulty) { returnStr += "Difficulty:" +difficultyStrings[obj.task.difficulty]+"\n"; }
                          if(obj.task.focus) { returnStr += "Focus:" +focusStrings[obj.task.focus]+"\n"; }
                          return returnStr;
                        }}
                        onNavigate={(date)=>{
                          fetchTasks(moment(date, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)"));
                        }}
                        onSelectSlot={(obj)=>{
                          setAppContext("onSelectSlot");
                          if(obj.action === 'select') {
                            let setStatus = 0;
                            let eventstart = obj.start;
                            let eventend = obj.end;
                            let start = moment(obj.start, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)");
                            let end = moment(obj.end, "ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)");
                            start.set('second', 0);
                            let duration = end.diff(start,'seconds');
                            if(duration > 14400) {
                              duration = "14400";
                            }
                            /*
                            if(start.diff(now) < 0) {
                              start = moment(now);
                              eventstart = start.format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ (zz)");
                              setStatus = 4;
                            }
                            if(end.diff(now) < 0) {
                              eventend = now.add(duration, 'seconds');
                            }
                            */
                            let _event = {
                              "title": "No Title",
                              "task": {
                                "id":0,
                                "name": "No Title",
                                "projectID": 0,
                                "startDateTime": start.format("YYYY-MM-DD hh:mm:ss A"),
                                "status":setStatus,
                                "notificationStatus":0,
                                "notificationEndStatus":0,
                                "duration": duration.toString(),
                                "timeSpent": "0",
                                "savedEvent": false
                                },
                              "start": eventstart,
                              "end": eventend
                            }
                            setOpenCreateTask(_event);
                          }
                        }}
                        eventPropGetter={(event)=>{
                          if(event.task.status === 0) {
                            let color = '#69707D';
                            let projectObj = event.task.project;
                            if(!projectObj) {
                              projectObj = projects.find( ({ key }) => key === event.task.projectID );
                            }
                            
                            if(projectObj) {
                              if(projectObj.color) {
                                color = projectObj.color;
                              }
                            }
                            return {
                              className: 'scheduledTask',
                              style: {
                                'backgroundColor': color,
                              },
                            }
                          } else if(event.task.status === 1) {
                            return {
                              className: 'startedTask',
                              style: {
                                'backgroundColor': '#07C',
                              },
                            }
                          } else if(event.task.status === 2) {
                            let color = '#00c853';
                            let projectObj = event.task.project;
                            if(!projectObj) {
                              projectObj = projects.find( ({ key }) => key === event.task.projectID );
                            }
                            
                            if(projectObj) {
                              if(projectObj.color) {
                                color = projectObj.color;
                              }
                            }
                            return {
                              className: 'doneTask',
                              style: {
                                'backgroundColor': color,
                              },
                            }
                          } else if(event.task.status === 3) {
                            return {
                              className: 'pausedTask',
                              style: {
                                'backgroundColor': '#FEC514'
                              },
                            }
                          }
                          else return {}
                        }}
                        components = {
                          {
                            eventWrapper: (props) => <PopoutEvent props={props} userData={userData} events={events} setEvents={setEvents} projects={projects} setProjects={setProjects} activeTask={activeTask} setActiveTask={setActiveTask} userSettings={userSettings} activeCalendar={activeCalendar}/>
                          }
                        }
      
                      />
                    </EuiPanel>}
                    { selectedTab === "List" && <TaskList events={events} setEvents={setEvents} projects={projects} userData={userData}/>
                    }
                  {openStopTask && <StopTaskModal 
                    activeTask={activeTask} 
                    events={events}
                    setEvents={setEvents}
                    setOpenStopTask={setOpenStopTask}
                  />}
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageTemplate>}
        <div id="Modal">
          <CreateTaskModal 
            userData={userData} 
            openCreateTask={openCreateTask} 
            setOpenCreateTask={setOpenCreateTask} 
            events={events} 
            setEvents={setEvents} 
            projects={projects}
            setProjects={setProjects}
            userSettings={userSettings}
            activeCalendar={activeCalendar}
          />
        </div>
        <div id="FirstVisitModal">
          {<FirstVisitModal 
            userData={userData} 
            userSettings={userSettings}
            tourActions={tourActions}
            setUserSettings={setUserSettings}
            />}
        </div>
      </div>
    );
}