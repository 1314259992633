import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { API } from 'aws-amplify';
import { 
    updateTaskFn as updateTaskFnMutation
  } from './graphql/mutations';  
import {
    EuiHeaderSectionItemButton,
    EuiButtonEmpty,
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiTourStep,
    EuiPopover,
    EuiFormRow,
    EuiAvatar,
    EuiHeaderLogo,
    EuiButton,
    EuiKeyPadMenuItem,
    EuiIcon,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiSpacer,
    EuiModalFooter,
    EuiCallOut,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiSelectable
  } from '@elastic/eui';
import { useGlobalState } from './GlobalState';
import { CalendarSelect, CalendarShare } from './Calendar';
import OutlookImport from './OutlookImport';

function CloseOldTasks({events, setEvents, setIsToolsMenuOpen, keyPad, activeCalendar}) {
    const [openCloseOldTasks, setOpenCloseOldTasks] = useState(false);
    const [allowCreateTask,setAllowCreateTask] = useState(true);
  
    useEffect(() => {
      setAllowCreateTask(activeCalendar.createtask);
    }, [activeCalendar]);
  
    async function closeOldTasksFn() {
      let now = moment();
      let start = moment().subtract(30,'d');
  
      let filteredEvents = events.filter(event => (moment(event.task.startDateTime,moment.ISO_8601).diff(start) >= 0 && moment(event.task.startDateTime,moment.ISO_8601).diff(now) <= 0 && event.task.status !== 2));
      let oldEvents = events.filter(event=> !(moment(event.task.startDateTime,moment.ISO_8601).diff(start) >= 0 && moment(event.task.startDateTime,moment.ISO_8601).diff(now) <= 0 && event.task.status !== 2));
      for(let i in filteredEvents) {
        filteredEvents[i].task.status = 2;
        filteredEvents[i].task.timeSpent = filteredEvents[i].task.duration;
        filteredEvents[i].task.difficulty = 3;
        filteredEvents[i].task.focus = 3;
        filteredEvents[i].task.endDateTime = moment(filteredEvents[i].task.startDateTime, "YYYY-MM-DDThh:mm:ss.sssZ").add(filteredEvents[i].task.duration, 'seconds').toISOString();
        API.graphql({ query: updateTaskFnMutation, variables: { input: {id: filteredEvents[i].task.id, 'status':2, 'timeSpent': filteredEvents[i].task.timeSpent, 'difficulty':filteredEvents[i].task.difficulty, 'focus':filteredEvents[i].task.focus}} });
      }
      setEvents([...oldEvents,...filteredEvents]);
    }
  
    return (
      <>
        {keyPad ? 
        <EuiKeyPadMenuItem
          label="Close old tasks"
          isDisabled={!allowCreateTask}
          onClick ={()=>{
            setOpenCloseOldTasks(true);
          }}
        >
          <EuiIcon type="stop" size="l" color="danger"/>
        </EuiKeyPadMenuItem> 
        :
        <EuiButtonEmpty isDisabled={!allowCreateTask} onClick ={()=>{
          setOpenCloseOldTasks(true);
        }}><EuiAvatar name="Stop" type="space" iconType="stop" size="s" color="#BD271E" />&nbsp;Close old tasks</EuiButtonEmpty>
        }
        {openCloseOldTasks && <EuiModal
          onClose={()=>{setOpenCloseOldTasks(false);}}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1><EuiAvatar name="Stop" type="space" iconType="stop" size="m" color="#BD271E" />&nbsp;Close Old Tasks</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiCallOut title="Note" color="warning" iconType="help">
              <p>
                This action will close old tasks from the start of the week till now. The tasks will be populated with the following default values: <br/>
                <ul>
                  <li>Timespent: will be set to the duration selected.</li>
                  <li>Difficulty: Fair</li>
                  <li>Focus: Average</li>
                </ul>
                Please review all your tasks before continuing with this action. You can modify tasks by selecting the task and clicking on the <EuiAvatar name="Edit" type="space" iconType="documentEdit" size="s" color="#69707D" /> edit button.
              </p>
            </EuiCallOut>
            <EuiSpacer />
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton 
              color="danger" 
              onClick={()=>{
                setOpenCloseOldTasks(false);
                if(setIsToolsMenuOpen) {
                  setIsToolsMenuOpen(false);
                }
              }} 
              fill
            >
              Cancel
            </EuiButton>
            <EuiButton 
              fill
              color="danger"
              onClick={() => {
                closeOldTasksFn();
                setOpenCloseOldTasks(false);
                if(setIsToolsMenuOpen) {
                  setIsToolsMenuOpen(false);
                }
              }}
            >
              Close All
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>}
      </>
    );
  }

function TopMenu({userData, activeMenu, setActiveMenu, events, setEvents, projects, setProjects, userSettings, setUserSettings, stats, euiTourSteps, tourActions, tourReducerState, activeCalendar, setActiveCalendar, calendars, sharedToMeCalendars, setSharedToMeCalendars, sharedByMeCalendars, setSharedByMeCalendars, logOut}) { 
    const [timeCDSpent, setTimeCDSpent] = useGlobalState('timeCDSpent');
    const [timerIntTaskActive, setTimerIntTaskActive] = useGlobalState('timerIntTaskActive');
    const [isToolsMenuOpen, setIsToolsMenuOpen] = useState(false);
    const [disableProjectLink,setDisableProjectLink] = useState(false);
    const [disableLeaderBoardLink,setDisableLeaderBoardLink] = useState(true);
  
    useEffect(() => {
      if(activeCalendar.teamid) {
        setDisableProjectLink(true);
      } else {
        setDisableProjectLink(false);
      }
    }, [activeCalendar]);
  
    const toolsButton = (
      <EuiHeaderSectionItemButton 
        onClick = {()=>{
          setIsToolsMenuOpen(!isToolsMenuOpen);
        }}>
          <EuiButtonEmpty color='ghost'>
            Tools
          </EuiButtonEmpty>
      </EuiHeaderSectionItemButton>
    );
  
    return (
      <div>
        <EuiCallOut title="*** Preview instance ***" color="warning" iconType="help">
          This instance is for preview purposes only. Your event titles will not be saved.
        </EuiCallOut>
        <EuiHeader theme="dark">
          <EuiHeaderSectionItem>
            <img alt="More Options" src="logotimeblok.png" style={{"width": "125px", "display": "inline", "margin": 0}}/>
            <EuiHeaderLinks>
              <EuiHeaderLink isActive={activeMenu === 'home'} 
                onClick = {()=>{
                  setActiveMenu("home");
                }}>Home
              </EuiHeaderLink>
              {/*
              <EuiHeaderLink isActive={activeMenu === 'analyze'} 
                onClick = {()=>{
                  setActiveMenu("analyze");
                }}>Analyze
              </EuiHeaderLink>
              */}
              <EuiTourStep
                {...euiTourSteps[3]}
              >
                <EuiTourStep
                  {...euiTourSteps[8]}
                >
                  <EuiHeaderLink isActive={activeMenu === 'reports'}
                    onClick = {()=>{
                      setActiveMenu("reports");
                      if(tourReducerState.currentTourStep === 4 || tourReducerState.currentTourStep === 9) {
                        tourActions.incrementStep();
                      }
                    }}>Reports
                  </EuiHeaderLink>
                </EuiTourStep>
              </EuiTourStep>
              { !disableProjectLink &&
              <EuiTourStep
                {...euiTourSteps[5]}
              >
                <EuiHeaderLink isActive={activeMenu === 'projects'}
                  onClick = {()=>{
                    setActiveMenu("projects");
                    tourActions.incrementStep();
                  }}>Projects
                </EuiHeaderLink>
              </EuiTourStep>
              }
              { !disableLeaderBoardLink &&
                <EuiHeaderLink isActive={activeMenu === 'leaderboard'}
                  onClick = {()=>{
                    setActiveMenu("leaderboard");
                  }}>LeaderBoard
                </EuiHeaderLink>

              }
              <EuiPopover
                button={toolsButton}
                isOpen={isToolsMenuOpen}
                anchorPosition="downRight"
                closePopover={()=>{setIsToolsMenuOpen(false)}}
                panelPaddingSize="s"
              >
                <EuiFormRow><CloseOldTasks events={events} setEvents={setEvents} setIsToolsMenuOpen={setIsToolsMenuOpen} activeCalendar={activeCalendar}/></EuiFormRow>
                <EuiFormRow>
                  <OutlookImport setIsToolsMenuOpen={setIsToolsMenuOpen} userData={userData} projects={projects} setProjects={setProjects} userSettings={userSettings} setUserSettings={setUserSettings} events={events} setEvents={setEvents} euiTourSteps={euiTourSteps} tourActions={tourActions} tourReducerState={tourReducerState} activeCalendar={activeCalendar}/>
                </EuiFormRow>
                <EuiFormRow>
                  <CalendarShare userData={userData} calendars={calendars} sharedToMeCalendars={sharedToMeCalendars} setSharedToMeCalendars={setSharedToMeCalendars} sharedByMeCalendars={sharedByMeCalendars} setSharedByMeCalendars={setSharedByMeCalendars} setIsToolsMenuOpen={setIsToolsMenuOpen}/>
                </EuiFormRow>
              </EuiPopover>
              <EuiHeaderLink isActive={activeMenu === 'team'}
                onClick = {()=>{
                  setActiveMenu("team");
                }}>Team
              </EuiHeaderLink>
              <EuiHeaderLink isActive={activeMenu === 'settings'}
                onClick = {()=>{
                  setActiveMenu("settings");
                }}>Settings
              </EuiHeaderLink>
              <EuiHeaderLink
                href="https://w.amazon.com/bin/view/TimeBlok/"
                >Wiki
              </EuiHeaderLink>
              <EuiHeaderLink
                href="https://blog.timeblok.app/"
                >Blog
              </EuiHeaderLink>
              <EuiHeaderLink
                href="https://amzn-aws.slack.com/archives/C03MR7VJAPL"
                >Join Slack Channel
              </EuiHeaderLink>
              <EuiHeaderLink
                href="https://tt.amazon.com/create/templates/54f6cd24-14a7-4e24-a2be-fe917f5d024e"
                >Report Bug
              </EuiHeaderLink>
            </EuiHeaderLinks>
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>
          <EuiHeaderLogo iconType="recentlyViewedApp">{stats.Users} Amazonians have used this tool.</EuiHeaderLogo>
            <EuiHeaderLinks>
              {timerIntTaskActive.id && <EuiButton onClick={()=>{
                document.querySelector("#EventDiv-"+timerIntTaskActive.id).children[0].click();
              }} fill iconType="playFilled">Active: {timerIntTaskActive.name} {new Date((timerIntTaskActive.duration - timeCDSpent) * 1000).toISOString().substr(11, 8)}</EuiButton>}
              <EuiAvatar type="user" size="l" name={userData.attributes.given_name+" "+userData.attributes.family_name} imageUrl={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+userData.username.replace("amazonfederate_","")} />
              <EuiHeaderLink onClick={()=> logOut()}>Logout</EuiHeaderLink>
            </EuiHeaderLinks>
          </EuiHeaderSectionItem>
        </EuiHeader>
        
      </div>
    );
  }

export default TopMenu