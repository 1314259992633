import React from 'react';
import { API } from 'aws-amplify';
import { 
    updateUserFn as updateUserFnMutation
} from './graphql/mutations'; 
import {
    EuiFormRow,
    EuiIcon,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiSelect,
    EuiSwitch,
    EuiPageTemplate,
    useEuiTheme,
    logicals
  } from '@elastic/eui';
import { css } from '@emotion/react';
import { timeFormats } from './Constants';

export function SettingsPage({userData,userSettings,setUserSettings,notificationPermission,setNotificationPermission,displayStartTime,setActiveMenu}) {
    const { euiTheme } = useEuiTheme();
    return(
      <>
        <EuiPageTemplate restrictWidth="100%" template="empty">
            <div>
          <EuiFlexGroup>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    padding-left: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Notifications</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="alert" size='l'/>Enabled Notification Types</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow
                    isInvalid={!notificationPermission}
                    error={"Disabled in Browser."}
                  >
                    <EuiSwitch 
                      label='Web' 
                      checked={userSettings.webnotify} 
                      disabled={!notificationPermission} 
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'webnotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'webnotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Email' 
                      checked={userSettings.emailnotify}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'emailnotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'emailnotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow
                    isInvalid={!userSettings.phone}
                    error={"No phone number set."}
                  >
                    <EuiSwitch 
                      label='SMS' 
                      checked={userSettings.phonenotify}
                      disabled={!userSettings.phone} 
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'phonenotify': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'phonenotify':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="clock" size='l'/>Notification Timing</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='10 Minute Notification' 
                      checked={userSettings.ftmin}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'ftmin': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'ftmin':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='5 Minute Notification' 
                      checked={userSettings.fvmin}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'fvmin': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'fvmin':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Calendar</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Outlook Integration</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Task Email Invites' 
                      checked={userSettings.calendarInvite}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'calendarInvite': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: {'calendarInvite':e.target.checked}} }).catch((error)=>{});
                      }}/>
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="clock" size='l'/>Time Format</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Time Format'
                      options={timeFormats}
                      value={Number(userSettings.timeformat)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'timeformat': e.target.value});
                        API.graphql({ query: updateUserFnMutation, variables: { input: {'timeformat':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Display Start Time</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Display Start Time'
                      options={displayStartTime}
                      value={Number(userSettings.calstarttime)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'calstarttime': e.target.value});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'calstarttime':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="calendar" size='l'/>Display End Time</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSelect
                      placeholder='Display End Time'
                      options={displayStartTime}
                      value={Number(userSettings.calendtime)}
                      onChange={(e) => {
                        setUserSettings({ ...userSettings, 'calendtime': e.target.value});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'calendtime':e.target.value}} }).catch((error)=>{});
                      }}
                    />
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem
                css={css`
                    padding-top: calc(${euiTheme.size.s});
                    ${logicals['width']}: 300px;
                `}>
                <EuiPanel>
                  <EuiText style={{ textAlign: 'center' }}>
                    <h3>Other Settings</h3>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiText>
                    <h4><EuiIcon type="training" size='l'/>Tutorial</h4>
                  </EuiText>
                  <EuiSpacer/>
                  <EuiFormRow>
                    <EuiSwitch 
                      label='Tutorial Enabled' 
                      checked={userSettings.firstVisit}
                      onChange={(e)=>{
                        setUserSettings({ ...userSettings, 'firstVisit': e.target.checked});
                        API.graphql({ query: updateUserFnMutation, variables: { input: { 'firstVisit':e.target.checked}} }).catch((error)=>{});;
                        setActiveMenu("home");
                      }}/>
                  </EuiFormRow>
                </EuiPanel>
              </EuiFlexItem>
          </EuiFlexGroup>
          </div>
        </EuiPageTemplate>
      </>
    )
  }